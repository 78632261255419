import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { IconCircleDashed } from '@tabler/icons-react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
// eslint-disable-next-line import/no-cycle
import { Dialog } from '../Dialog'

const Button = ({
  background,
  borderless,
  className,
  confirm,
  dataTestId,
  disabled,
  fullWidth,
  icon,
  id,
  label,
  loading,
  onClick,
  outlined,
  plain,
  size,
  type,
  ariaLabel,
}) => {
  // State
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const configureBackground = () => {
    let baseStyles = 'border-blue-800 bg-blue-800 text-white'
    let hoverStyles = 'hover:bg-blue-900 hover:border-blue-900'

    if (background === 'bg-white') {
      baseStyles = 'border-gray-light text-gray'
      hoverStyles = 'hover:bg-gray-100 hover:border-gray-100'
    }
    if (background === 'bg-red') {
      baseStyles = 'border-red text-white'
      hoverStyles = 'hover:bg-red-900 hover:border-red-900'
    }
    if (background === 'bg-background') {
      baseStyles = 'border-blue-800 text-blue-800'
      hoverStyles = 'hover:border-blue-900 hover:text-blue-900'
    }
    if (background === 'bg-green') {
      baseStyles = 'border-green-700 text-white'
      hoverStyles = 'hover:border-green-900 hover:text-white hover:bg-green-900'
    }

    if (disabled) hoverStyles = 'hover:cursor-not-allowed'

    return `${background} ${baseStyles} ${hoverStyles}`
  }

  const configureClass = () => {
    let base =
      'group relative flex justify-center border border-[1.5px] uppercase leading-5 tracking-[3px] disabled:opacity-50'
    let updates = ''

    // Configure border radius, padding and text size
    switch (size) {
      case 'xs':
        base = `${base} rounded py-0.5 px-1 text-xs`

        if (icon || loading) base = `${base} pl-8 pr-2`
        break
      case 'sm':
        base = `${base} rounded-md py-2 px-3 text-sm leading-4`

        if (icon || loading) base = `${base} pl-9 pr-3`
        break
      case 'md':
        base = `${base} rounded-md py-3 px-4 text-sm leading-5`

        if (icon || loading) base = `${base} sm:pl-9 sm:pr-3.5 pl-7 pr-2`
        break
      case 'lg':
        base = `${base} rounded-md py-3 px-4 text-base`

        if (icon || loading) base = `${base} pl-9 pr-3.5`
        break
      case 'xl':
        base = `${base} rounded-md py-3.5 px-5 text-base`

        if (icon || loading) base = `${base} pl-9 pr-3.5`
        break
      default:
        base = `${base} rounded-md py-3 px-4 text-sm`

        if (icon || loading) base = `${base} pl-8 pr-2.5`
    }

    if (fullWidth) {
      updates = `${updates} w-full`
    }

    if (borderless) {
      updates = `${updates} border-transparent`
    }

    if (outlined) {
      updates = `${updates} border-blue-800 hover:border-blue-800`
    }

    return mergeClassNames(base, updates)
  }

  return (
    <>
      <button
        data-testid={dataTestId}
        id={id}
        onClick={() => {
          if (confirm) {
            setShowConfirmDialog(true)
          } else if (onClick) {
            onClick()
          }
        }}
        className={
          !plain ? mergeClassNames(configureClass(), configureBackground(), className) : className
        }
        disabled={disabled || loading}
        // eslint-disable-next-line react/button-has-type
        type={type || 'submit'}
        aria-label={ariaLabel || undefined}
      >
        {icon && (
          <span className="absolute inset-y-0 left-0 flex items-center pl-[10px]">{icon}</span>
        )}
        {label}
        {loading && (
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <div className="h-6 w-6">
              <svg className="motion-safe:animate-spin-slow mr-3 h-6 w-6" viewBox="0 0 24 24">
                <IconCircleDashed size={24} strokeWidth={2} color="white" />
              </svg>
            </div>
          </span>
        )}
      </button>

      {showConfirmDialog && (
        <Dialog
          {...confirm}
          onConfirm={() => {
            setShowConfirmDialog(false)
            onClick()
          }}
          onCancel={() => setShowConfirmDialog(false)}
        />
      )}
    </>
  )
}

Button.propTypes = {
  background: PropTypes.string,
  borderless: PropTypes.bool,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  confirm: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
  }),
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.element,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
  plain: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  type: PropTypes.string,
}

Button.defaultProps = {
  background: 'bg-blue',
  borderless: false,
  className: '',
  confirm: null,
  dataTestId: null,
  disabled: false,
  fullWidth: false,
  icon: null,
  id: null,
  loading: false,
  outlined: false,
  plain: false,
  size: 'md',
  type: 'submit',
  ariaLabel: undefined,
}

export default Button
