import { ArrowDownIcon } from '@heroicons/react/16/solid'
import PropTypes from 'prop-types'
import React from 'react'
import DataTable from 'react-data-table-component'

import baseColors from '../../utils/colors'

/**
 *
 * DataTable
 *
 * - Displays a table of data
 */
const CustomDataTable = ({
  columns,
  data,
  onChangePage,
  pagination,
  paginationServer,
  progressPending,
  responsive,
  title,
  highlightOnHover,
  ...rest
}) => (
  <DataTable
    columns={columns}
    highlightOnHover={highlightOnHover}
    customStyles={{
      responsiveWrapper: {
        style: {
          border: `1px solid #cfd0d2`,
          borderRadius: '16px',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
        },
      },
      columnHeader: {
        style: {
          backgroundColor: '#FFFFFF',
          borderBottomColor: `${baseColors.brownGray.DEFAULT}`,
        },
      },
      head: {
        style: {
          backgroundColor: `#FFFFFF`,
          borderBottomColor: `${baseColors.brownGray.DEFAULT}`,
          borderBottomWidth: '1px',
          paddingTop: '12px',
          paddingBottom: '15px',
        },
      },
      headRow: {
        style: {
          backgroundColor: `${baseColors.white.dark}`,
          fontSize: '10px',
          textTransform: 'uppercase',
          minHeight: '40px',
          color: `${baseColors.charcoal.DEFAULT}`,
          height: '44px',
          fontWeight: '700px',
          border: 'none',
        },
      },
      rows: {
        style: {
          fontSize: '16px',
          lineHeight: '20px',
          color: `${baseColors.charcoal[900]}`,
          minHeight: '52px',
          '&:not(:last-of-type)': {
            borderBottomColor: `${baseColors.brownGray.DEFAULT}`,
          },
          '&:last-of-type': {
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
          },
          cursor: highlightOnHover ? 'pointer' : 'default',
        },
      },
      pagination: {
        style: {
          color: '#3b3b3e',
          fontSize: '12px',
          minHeight: '30px',
          backgroundColor: 'transparent',
          border: 'none',
          fontWeight: '600',
        },
        pageButtonsStyle: {
          borderRadius: '50%',
          height: '32px',
          width: '32px',
          padding: '4px',
          margin: '5px',
          cursor: 'pointer',
          transition: '0.4s',
          outline: 'none',
          color: 'black',
          fill: 'black',
          backgroundColor: 'transparent',
          '&:disabled': {
            cursor: 'unset',
            color: 'black',
            opacity: 0.25,
            fill: 'black',
          },
          '&:hover:not(:disabled)': {
            backgroundColor: '#bee2f9',
            fill: 'white',
          },
          '&:focus': {
            outline: 'none',
            backgroundColor: '#bee2f9',
            fill: 'white',
          },
        },
      },
    }}
    data={data}
    fixedHeader
    fixedHeaderScrollHeight={200}
    onChangePage={onChangePage}
    pagination={pagination}
    paginationServer={paginationServer}
    progressPending={progressPending}
    title={title}
    responsive
    sortIcon={<ArrowDownIcon style={{ height: '12px' }} />}
    {...rest}
  />
)

CustomDataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onChangePage: PropTypes.func,
  pagination: PropTypes.bool,
  paginationServer: PropTypes.bool,
  progressPending: PropTypes.bool.isRequired,
  responsive: PropTypes.bool,
  title: PropTypes.string,
  highlightOnHover: PropTypes.bool,
}

CustomDataTable.defaultProps = {
  onChangePage: null,
  pagination: false,
  paginationServer: false,
  responsive: false,
  title: null,
  highlightOnHover: false,
}

export default CustomDataTable
