import dayjs from 'dayjs'
import { Parser } from 'html-to-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Tooltip } from '../../components/Tooltip'

// Icons
import { AddIcon } from '../../components/AddIcon'
import { AttachmentIcon } from '../../components/AttachmentIcon'
import { CommentsIcon } from '../../components/CommentsIcon'
import { MinusIcon } from '../../components/MinusIcon'

// // Utils
import { ACTION_ICONS } from '../../utils/constants'
import {
  configureActionItemIcon,
  configureRepairOrderAction,
  configureRepairOrderLineItem,
} from '../../utils/helpers'

const DETAIL_CONTAINER =
  'bg-white-dark flex flex-col items-center justify-center rounded-lg p-4 text-sm font-semibold shadow w-1/2 h-1/2 overflow-hidden'
const DATA_DETAILS = 'text-charcoal-900 text-lg font-semibold truncate'
const DATA = 'text-charcoal-900 text-md font-semibold truncate'
const LABEL = 'text-charcoal pb-1 text-xs font-semibold uppercase whitespace-nowrap'

/**
 *
 * RepairOrderCard
 *
 */
const RepairOrderCard = ({ item, requiresReview }) => {
  // State
  const [isOpen, setIsOpen] = useState(false)

  // Formatted dates
  const today = dayjs()
  const onList = today.startOf('day').diff(dayjs(item.createdAt).startOf('day'), 'day')
  const agesOut = dayjs(item.agesOut).startOf('day').diff(today.startOf('day'), 'day')

  const lastUpdatedOn = dayjs(item.lastUpdatedOn)
  const formattedLastUpdatedOn = lastUpdatedOn.format('MM/DD/YYYY')
  const notUpdatedInMoreThan5Days = today.diff(lastUpdatedOn, 'day') > 5

  const actions = _.map(
    _.flatMap(item.lineItems, (l) => _.map(l.actionsNeeded, (a) => a)),
    (a) => ({ name: a.action.name, completed: a.completedAt }),
  )
  const lineItemActions = _.map(item.lineItems, (l) => ({
    name: l.lineName,
    actions: _.flatMap(l.actionsNeeded, (a) => ({
      name: a.action.name,
      completed: a.completedAt,
    })),
  }))

  // Get the unique action icons and completed status
  const actionIcons = _.orderBy(
    _.uniqBy(
      _.map(actions, (a) => ({
        icon: ACTION_ICONS[a.name] || 'more',
        completed: a.completed,
        name: a.name,
      })),
      'icon',
    ),
    'completed',
    'desc',
  )

  /**
   * Summarize line items if there are multiple.
   * @param {object} data
   * @returns
   */
  const summarizeLineItems = ({ lineItems }) => {
    // Check if there are multiple line items
    if (lineItems.length > 1) {
      const resolvedCount = lineItems.filter((lineItem) => lineItem.state !== 'Pending').length

      return (
        <p className="text-charcoal-900 mt-2 truncate">{`Multiple Line Items (${resolvedCount} of ${lineItems.length} Resolved)`}</p>
      )
    }

    // Return nothing if there is only one line item or none
    return null
  }

  return (
    <div
      data-testid="repairOrderCard"
      className={`mb-4 flex h-auto w-[320px] flex-col space-y-4 rounded-lg border-t-[18px] bg-white p-4 shadow-md transition-all ease-in-out ${
        requiresReview ? 'border-gold-dark/90' : 'border-blue-950/90'
      }
      `}
    >
      {/* Top section with RO # and Amount */}
      <div className="flex h-auto flex-col items-center justify-between gap-4 xl:flex-row">
        <Link
          to={`/dealer/${item.dealer}/repair-order/${item.id}`}
          className={`${DETAIL_CONTAINER} hover:bg-blue-100`}
        >
          <div className="flex flex-row items-center gap-2">
            <p className="text-charcoal whitespace-nowrap text-xs font-semibold uppercase">RO #</p>{' '}
            <div
              className={`h-[13px] w-[13px] shrink-0 rounded-full border-[3px] ${
                item.isOpen ? 'border-green-800' : 'border-charcoal-400 bg-charcoal-400'
              }`}
            />
          </div>
          <div className="flex w-full items-center justify-center gap-2">
            <p className={`${DATA_DETAILS} uppercase`}>{item.roNumber}</p>

            <p className="sr-only">Repair Order is {item.isOpen ? 'open' : 'not open'}</p>
          </div>
        </Link>

        <div className={DETAIL_CONTAINER}>
          <p className={LABEL}>Amount</p>

          <p className={DATA_DETAILS}>
            {Number(item.totalUnresolvedClaimAmount).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
          </p>
        </div>
      </div>

      {/* Bottom section with On List and Ages Out */}
      <div className="flex h-auto flex-col items-center justify-between gap-4 xl:flex-row">
        <div className={DETAIL_CONTAINER}>
          <div className={LABEL}>On List</div>
          <p className={DATA_DETAILS}>{onList}d</p>
        </div>
        <div className={mergeClassNames(DETAIL_CONTAINER, agesOut < 7 && 'bg-red-700/10')}>
          <p className={LABEL}>Ages Out</p>
          <span className={mergeClassNames(DATA_DETAILS, agesOut < 7 && 'text-red')}>
            {agesOut}d{agesOut > 1 || agesOut === 0}
          </span>
        </div>
      </div>

      {/* Action Needed */}
      <div>
        <div className="flex w-full items-center justify-between">
          <p className={LABEL}>Action Needed</p>

          <div className="flex flex-wrap items-center gap-2">
            {_.map(actionIcons, (a) => (
              <Tooltip
                placement="top"
                content={
                  <div className="bg-white-dark text-md rounded-lg border border-white p-1.5 text-blue-800 shadow-md">
                    <span>{a.name}</span>
                  </div>
                }
              >
                {configureActionItemIcon(a.icon, a.completed)}
                <span className="sr-only">{a.name}</span>
              </Tooltip>
            ))}
          </div>
        </div>

        {!isOpen && summarizeLineItems(item)}

        {isOpen && actions.length > 0 && (
          <div role="list" className="mt-2 flex flex-col gap-2">
            {lineItemActions.length > 1
              ? _.map(lineItemActions, configureRepairOrderLineItem)
              : _.map(actions, (a) => configureRepairOrderAction(a))}
          </div>
        )}
      </div>

      {/* Problem Preview */}
      {!isOpen && (
        <>
          <div className="flex w-full flex-col">
            <p className={LABEL}>Problem</p>

            <div className="text-charcoal-900 h-5 overflow-y-hidden">
              {_.map(item.lineItems, (l) => Parser().parse(l.problem))}
            </div>
          </div>

          <div className="flex w-full items-center justify-between">
            <p className={LABEL}>Last Updated</p>

            <p className={mergeClassNames(DATA, notUpdatedInMoreThan5Days && 'text-red')}>
              {formattedLastUpdatedOn}
            </p>
            {notUpdatedInMoreThan5Days && (
              <span className="sr-only">Not updated in more than 5 days</span>
            )}
          </div>
        </>
      )}

      {isOpen && (
        <div className="mt-2">
          <div className="flex w-full flex-col">
            <p className={LABEL}>Problem</p>

            <div className="text-charcoal-900">
              {_.map(item.lineItems, (l) => Parser().parse(l.problem))}
            </div>
          </div>

          <div className="mt-4 flex w-full items-center justify-between gap-x-2">
            <p className={LABEL}>Tech #</p>

            <p className={DATA}>{_.map(item.lineItems, (l) => l.technician).join(' ')}</p>
          </div>

          <div className="mt-4 flex w-full items-center justify-between gap-x-2">
            <p className={LABEL}>ADV ID</p>

            <p className={DATA}>{item.advisor}</p>
          </div>

          <div className="mt-4 flex w-full items-center justify-between gap-x-2">
            <p className={LABEL}>Last Updated</p>

            <p className={mergeClassNames(DATA, notUpdatedInMoreThan5Days && 'text-red')}>
              {formattedLastUpdatedOn}
            </p>
            {notUpdatedInMoreThan5Days && (
              <span className="sr-only">Not updated in more than 5 days</span>
            )}
          </div>
        </div>
      )}

      {/* Action button and expandable section */}
      <div className="mt-2 flex max-w-[380px] flex-col items-center justify-between text-center md:flex-row ">
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center justify-center border-none bg-white text-sm font-semibold uppercase leading-5 tracking-[3px] text-blue-800"
        >
          {isOpen ? (
            <MinusIcon className="mr-2 h-4 stroke-blue-800" />
          ) : (
            <AddIcon className="mr-2 h-4 stroke-blue-800" />
          )}
          {isOpen ? 'Hide Details' : 'View Details'}
        </button>
        <div>
          <div className="flex flex-row gap-3">
            <AttachmentIcon
              className={mergeClassNames(
                'h-6',
                item.hasUnreadAttachments ? 'stroke-blue-800' : 'stroke-charcoal-light',
              )}
              unread={item.hasUnreadAttachments}
            />
            {item.hasUnreadAttachments && (
              <span className="sr-only">Admin has unread attachments</span>
            )}

            <CommentsIcon
              className={mergeClassNames(
                'h-6',
                item.hasUnreadComments ? 'fill-blue-800' : 'fill-charcoal-light',
              )}
              unread={item.hasUnreadComments}
            />
            {item.hasUnreadComments && <span className="sr-only">Admin has unread comments</span>}
          </div>
        </div>
      </div>
    </div>
  )
}

RepairOrderCard.propTypes = {
  item: PropTypes.shape({
    hasUnreadAttachments: PropTypes.bool.isRequired,
    hasUnreadComments: PropTypes.bool.isRequired,
    advisor: PropTypes.string.isRequired,
    agesOut: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    dealer: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isAgesOutOverridden: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    timeClockTriggerDate: PropTypes.string.isRequired,
    lastUpdatedOn: PropTypes.string.isRequired,
    lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    manufacturer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      modifiedAt: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
    }).isRequired,
    modifiedAt: PropTypes.string.isRequired,
    roNumber: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    totalUnresolvedClaimAmount: PropTypes.string.isRequired,
  }).isRequired,
  requiresReview: PropTypes.bool.isRequired,
}
export default RepairOrderCard
