import { Field, Label } from '@headlessui/react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Components
import { CheckBox } from '../../components/CheckBox'
import { DatePicker } from '../../components/DatePicker'
import { Modal } from '../../components/Modal'
import { Textarea } from '../../components/Textarea'
import { TextInput } from '../../components/TextInput'

// Utils & Services
import { updateBillingCycle } from '../../services/billing.service'
import { toast } from '../../utils/helpers'

const CHECKBOX_VALUES = [
  { id: 'isCleanup', label: 'Clean Up' },
  { id: 'isFinalBilling', label: 'Final Billing' },
  { id: 'isDealerReimbursement', label: 'Dealer Reimbursements' },
]

const DEFAULT_FORM_VALUES = {
  isCleanup: false,
  isFinalBilling: false,
  isDealerReimbursement: false,
  cleanupSchedule: '',
  cleanupHoursSpent: '',
  cleanupApprovedBy: '',
  notesForBilling: '',
}

/**
 *
 * ApprovalModal
 *
 */
const ApprovalModal = ({
  showModal,
  setShowModal,
  dealerId,
  billingCycleId,
  getUpdatedBillingCycle,
}) => {
  // State
  const [loading, setLoading] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)

  const handleSuccess = (message) => toast(message, 'success')
  const handleError = (message) => toast(message, 'error')

  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
  })

  const handleCloseModal = () => {
    setShowModal(false)
    getUpdatedBillingCycle()
  }

  useEffect(() => {
    if (!showModal) {
      reset(DEFAULT_FORM_VALUES)
    }
  }, [showModal, reset])

  /**
   * Handles updating the billing cycle.
   */
  const onSubmit = (data) => {
    const payload = { ...data, markAsSubmittedToBilling: true }

    // Only send `cleanupDate` if `isCleanup` is true
    if (payload.isCleanup) {
      payload.cleanupDate = dayjs(payload.cleanupDate).format('YYYY-MM-DD')
    } else {
      payload.cleanupDate = null
    }

    updateBillingCycle(dealerId, billingCycleId, payload, setLoading, handleError, () => {
      handleSuccess('Credit memos submitted to Billing.')
      handleCloseModal()
    })
  }

  return (
    <Modal
      open={showModal}
      title="Approval Notes"
      setOpen={setShowModal}
      loading={loading}
      content={
        <div className="my-4 flex h-full flex-col gap-4">
          <Field>
            <Label className="text-charcoal text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
              Does this billing cycle require any of the following?
            </Label>
            <div className="mt-2 space-y-2">
              {CHECKBOX_VALUES.map((item) => (
                <CheckBox key={item.id} {...register(item.id)} label={item.label} />
              ))}
            </div>
          </Field>
          {/* Conditional Cleanup Fields */}
          {watch('isCleanup') && (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4 sm:flex-row">
                <Controller
                  name="cleanupDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      disabled={loading}
                      dataTestId="cleanupDate"
                      label="Date"
                      onChange={onChange}
                      show={showDatePicker}
                      setShow={setShowDatePicker}
                      value={value}
                      name="cleanupDate"
                      id="cleanupDate"
                    />
                  )}
                />
                <TextInput
                  data-testid="cleanupSchedule"
                  error={errors.cleanupSchedule && 'This field is required'}
                  label="Schedule"
                  fullWidth
                  required
                  {...register('cleanupSchedule')}
                />
              </div>
              <div className="flex flex-col gap-4 sm:flex-row">
                <TextInput
                  data-testid="cleanupHoursSpent"
                  error={errors.cleanupHoursSpent && 'This field is required'}
                  label="Hours Spent"
                  fullWidth
                  type="number"
                  required
                  {...register('cleanupHoursSpent')}
                />
                <TextInput
                  data-testid="cleanupApprovedBy"
                  label="Approved By (Dealer)"
                  error={errors.cleanupApprovedBy && 'This field is required'}
                  fullWidth
                  required
                  {...register('cleanupApprovedBy')}
                />
              </div>
            </div>
          )}
          {/* Notes Field */}
          <Textarea
            {...register('notesForBilling')}
            className="w-full rounded-md border p-2"
            label="Notes for Billing"
            rows={3}
          />
        </div>
      }
      actions={[
        {
          type: 'submit',
          label: 'Submit',
          onClick: handleSubmit(onSubmit),
        },
        { type: 'cancel', label: 'Cancel', onClick: handleCloseModal },
      ]}
      onClose={handleCloseModal}
    />
  )
}

export default ApprovalModal

ApprovalModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  dealerId: PropTypes.string.isRequired,
  billingCycleId: PropTypes.string.isRequired,
  getUpdatedBillingCycle: PropTypes.func.isRequired,
  selectedBillingCycle: PropTypes.object,
}
