import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'

// Components
import { LegalLinks } from '../../components/LegalLinks'
import { Button } from '../../components/Button'
import { FormHeader } from '../../components/FormHeader'
import { PasswordInput } from '../../components/PasswordInput'
import { TextInput } from '../../components/TextInput'

// Store
import { UserStoreContext } from '../../stores/UserStore'

// Utils and messages
import { toast, verifyPassword } from '../../utils/helpers'

// Service
import { resetPassword, validateResetToken } from '../../services/user.service'

/**
 *
 * PasswordReset
 *
 */
const PasswordReset = () => {
  // Context
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { setCurrentUser, isAuthenticated, setCurrentTokens } = useContext(UserStoreContext)

  const token = searchParams.get('token')
  const email = searchParams.get('email')
  const newUser = location.pathname === '/set-password'

  // State
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [staticError, setStaticError] = useState(null)

  const handleErrors = (m) => toast(m, 'error')

  useEffect(() => {
    if (token && !isAuthenticated) {
      validateResetToken(token, (err) => {
        if (err.includes('expired') && newUser) {
          setStaticError(err)
        } else if (err.includes('expired')) {
          setRedirect('/password-reset-request')
        } else {
          handleErrors(err)
        }
      })
    }
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  /**
   * Helper to set the redirect parameters based on whether or not the user is
   * setting or RE-setting a password
   */
  const getRedirectParams = () => {
    if (newUser) return '?created=true'
    if (redirect === '/password-reset-request') return '?tokenExpired=true'
    return '?reset=true'
  }

  /**
   * Handles form submission
   * - Logs the user in if successful (via setting tokens in the store)
   * @param {object} data
   */
  const onSubmit = async (data) => {
    await resetPassword(
      { ...data, token },
      handleErrors,
      setLoading,
      setCurrentTokens,
      setCurrentUser,
    )
  }

  return (
    <>
      {redirect && <Navigate to={{ pathname: '/login', search: getRedirectParams() }} />}

      <div className="flex h-full w-full justify-center px-4 sm:px-6 lg:px-8">
        <div className="z-10 mx-4 w-full max-w-md space-y-4 rounded-xl bg-[#FFFFFF] px-6">
          <FormHeader title={newUser ? 'Set your password' : 'Reset your password'}>
            <div className="flex w-full justify-center">
              <a
                href="/login"
                className="text-sm font-semibold uppercase leading-[14px] tracking-[1.5px] text-blue-800 hover:text-blue-900"
              >
                Log In
              </a>
            </div>
          </FormHeader>

          {!staticError && (
            <form
              className="mt-8 flex flex-col gap-4"
              id="password-reset-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col gap-4">
                {newUser && (
                  <TextInput
                    className="bg-brownGray-100"
                    data-testid="email"
                    disabled
                    id="email"
                    placeholder="Email"
                    value={email}
                  />
                )}

                <Controller
                  error={errors.password && 'This field is required'}
                  name="password"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => {
                      const err = verifyPassword(value)
                      if (err) return err
                      return undefined
                    },
                  }}
                  render={({ field }) => (
                    <PasswordInput
                      className="bg-brownGray-100"
                      error={
                        errors.password && (errors.password.message || 'This field is required')
                      }
                      id="password"
                      label="Password"
                      placeholder="Password"
                      autoComplete="new-password"
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value, values) => {
                      const err = verifyPassword(value)
                      if (err) return err
                      if (value && value !== values.confirmPassword)
                        return 'Passwords do not match'
                      return undefined
                    },
                  }}
                  render={({ field }) => (
                    <PasswordInput
                      className="bg-brownGray-100"
                      error={
                        errors.confirmPassword &&
                        (errors.confirmPassword.message || 'This field is required')
                      }
                      id="confirmPassword"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      autoComplete="new-password"
                      {...field}
                    />
                  )}
                />
              </div>

              {/* Submit */}
              <Button fullWidth label="Submit" loading={loading} />
            </form>
          )}

          <LegalLinks />

          <div className="h-[1px]" />
        </div>
      </div>
    </>
  )
}

export default PasswordReset
