import React from 'react'

const LegalLinks = () => (
  <div className="mt-6 flex w-full justify-center">
    <a
      href="/privacy-policy"
      className="text-sm font-semibold uppercase leading-[14px] tracking-[1.5px] text-blue-800 hover:text-blue-900"
    >
      Privacy Policy
    </a>
  </div>
)

export default LegalLinks
