import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

// Components
import { Modal } from '../../components/Modal'

// Utils & Services
import { getDealerCreditMemos, updateBillingCycle } from '../../services/billing.service'
import { formatCurrency } from '../../utils/formatters'
import { toast } from '../../utils/helpers'

/**
 *
 * SubmitForApprovalModal
 *
 */
const SubmitForApprovalModal = ({
  showModal,
  setShowModal,
  dealerId,
  billingCycleId = '',
  getUpdatedBillingCycle,
  selectedBillingCycle,
}) => {
  // State
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({ totalAmountSum: '', allFiles: [], memoIds: [] })
  const [loadingCreditMemos, setLoadingCreditMemos] = useState(null)

  const handleSuccess = (message) => toast(message, 'success')
  const handleError = (message) => toast(message, 'error')

  const { handleSubmit } = useForm()

  const processMemos = (memos) => {
    // Extract files from each memo and flatten the array
    const allFiles = _.flatMap(memos, (memo) =>
      memo.files.map((file) => ({
        id: file.id,
        url: file.file,
        displayName: file.displayName,
      })),
    )

    // Extract the memo ids
    const memoIds = memos.map((memo) => ({ id: memo.id, memoId: memo.memoId }))
    setData({ totalAmountSum: selectedBillingCycle.totalAmount, allFiles, memoIds })
  }

  useEffect(() => {
    if (showModal) {
      const getData = async () => {
        const response = await getDealerCreditMemos(
          `/dealers/${dealerId}/billing-cycles/${selectedBillingCycle?.id}/credit-memos/?expand=created_by,add_backs,unbillables,files&limit=99999`,
          setLoadingCreditMemos,
          handleError,
        )
        if (response) {
          processMemos(response.results)
        }
      }
      getData()
    }
  }, [showModal])

  /**
   * Handles updating the billing cycle.
   */
  const onSubmit = () => {
    updateBillingCycle(
      dealerId,
      billingCycleId,
      {
        markAsSubmittedForApproval: true,
      },
      setLoading,
      handleError,
      () => {
        handleSuccess('Credit memos submitted for approval.')
        setShowModal(false)
        getUpdatedBillingCycle()
      },
    )
  }

  return (
    <Modal
      open={showModal}
      title="Are You Sure?"
      setOpen={setShowModal}
      loading={loading || loadingCreditMemos}
      content={
        <div className="mt-4 flex h-full flex-col gap-4">
          <p>
            Please confirm that the following total, included memos, and attachments are correct.
          </p>
          <div className="text-charcoal-950 flex flex-row gap-2 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
            <span>TOTAL TO SUBMIT:</span>
            <span>{formatCurrency(data.totalAmountSum)}</span>
          </div>
          <div className="mb-6 flex flex-col gap-4 sm:flex-row">
            <div className="w-full sm:w-1/2">
              <div className="text-charcoal-950 mb-2 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
                Memos Included
              </div>
              {data.memoIds.length > 0 ? (
                <ul className="ml-2 max-h-[200px] list-inside list-disc overflow-y-auto">
                  {data.memoIds.map((memo) => (
                    <li key={memo.id} className="text-sm">
                      {memo.memoId}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No memos included.</p>
              )}
            </div>
            <div className="w-full sm:w-1/2">
              <div className="text-charcoal-950 mb-2 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
                Attachments
              </div>
              {data.allFiles.length > 0 ? (
                <ul className="ml-2 max-h-[200px] list-inside list-disc overflow-y-auto">
                  {data.allFiles.map((file) => (
                    <li key={file.id}>
                      <a
                        href={file.url}
                        className="text-sm text-blue-800 hover:underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {file.displayName}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No attachments included.</p>
              )}
            </div>
          </div>
        </div>
      }
      actions={[
        {
          type: 'submit',
          label: 'Submit',
          onClick: handleSubmit(onSubmit),
        },
        { type: 'cancel', label: 'Cancel' },
      ]}
      onClose={() => setShowModal(false)}
    />
  )
}

export default SubmitForApprovalModal

SubmitForApprovalModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  dealerId: PropTypes.string.isRequired,
  billingCycleId: PropTypes.string,
  getUpdatedBillingCycle: PropTypes.func.isRequired,
  selectedBillingCycle: PropTypes.object,
}
