import { PlusIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

// Components
import { Button } from '../../components/Button'
import { DatePicker } from '../../components/DatePicker'
import { FileUploader } from '../../components/FileUploader'
import { Modal } from '../../components/Modal'
import { Select } from '../../components/Select'
import { TextInput } from '../../components/TextInput'

// Icons
import { DocumentCheckIcon } from '../../components/DocumentCheckIcon'
import { TrashIcon } from '../../components/TrashIcon'

// Stores
import { UserStoreContext } from '../../stores/UserStore'

// Utils & Services
import {
  createCreditMemo,
  createCreditMemoFile,
  updateCreditMemo,
} from '../../services/billing.service'
import { formatCurrency } from '../../utils/formatters'
import { toast, verifyCurrencyInputValue } from '../../utils/helpers'

const CreditMemoModal = ({
  dealerId,
  isEditMode = false,
  readOnly = false,
  creditMemo = {},
  billingCycles = [],
  loadingBillingCycles = false,
  closeModal,
}) => {
  // Context
  const { isWpcAdmin } = useContext(UserStoreContext)

  const [loading, setLoading] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [creditMemoDetails, setCreditMemoDetails] = useState({})
  const [filesToUpload, setFilesToUpload] = useState([])

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    reset,
    control,
    setValue,
  } = useForm({
    addBacks: [],
    unbillables: [],
    memoAmount: '',
    memoId: '',
    billingCycle: '',
  })

  const addBacks = watch('addBacks') || []
  const unbillables = watch('unbillables') || []
  const memoAmount = watch('memoAmount')

  // Calculate running totals
  const addBacksTotal = addBacks.reduce((sum, item) => sum + (Number(item?.amount) || 0), 0)
  const unbillablesTotal = unbillables.reduce((sum, item) => sum + (Number(item?.amount) || 0), 0)

  const {
    fields: addBacksFields,
    append: addAddBack,
    remove: removeAddBack,
    replace: replaceAddBacks,
  } = useFieldArray({
    control,
    name: 'addBacks',
  })

  const {
    fields: unbillablesFields,
    append: addUnbillable,
    remove: removeUnbillable,
    replace: replaceUnbillables,
  } = useFieldArray({
    control,
    name: 'unbillables',
  })

  useEffect(() => {
    if (billingCycles && billingCycles.length > 0) {
      if (creditMemo?.id) {
        setCreditMemoDetails(creditMemo)

        const billingCycle = _.find(billingCycles, { id: creditMemo.billingCycle })
        reset({
          ...creditMemo,
          billingCycle,
        })
      } else {
        const currentMonth = new Date().getMonth() + 1 // Get current month (1-based)
        const defaultCycle = billingCycles.find((cycle) => cycle.month === currentMonth)

        if (defaultCycle) {
          setValue('billingCycle', defaultCycle)
        }
      }

      if (creditMemo?.addBacks) {
        replaceAddBacks(creditMemo.addBacks)
      }

      if (creditMemo?.unbillables) {
        replaceUnbillables(creditMemo.unbillables)
      }
    }
  }, [billingCycles, creditMemo])

  /**
   * Handles saving the credit memo.
   * @param {object} data
   * @returns
   */
  const onSubmit = async (data) => {
    if (!creditMemoDetails.id) {
      const payload = {
        memoId: data.memoId.toUpperCase(),
        date: dayjs(data.date).format('YYYY-MM-DD'),
        memoAmount: data.memoAmount,
      }

      const response = await createCreditMemo(
        dealerId,
        data.billingCycle.id,
        payload,
        setLoading,
        handleError,
        () => {
          handleSuccess(`Credit memo successfully ${isEditMode ? 'updated' : 'added'}.`)
        },
      )

      if (response) {
        setCreditMemoDetails(response)
      }
    } else if (creditMemoDetails.id) {
      setLoading(true)

      await updateCreditMemo(
        dealerId,
        data.billingCycle.id,
        creditMemoDetails.id,
        {
          ...data,
          memoId: data.memoId.toUpperCase(),
          date: dayjs(data.date).format('YYYY-MM-DD'),
          // Send only the ids so we can remove the files we need to
          files: _.map(creditMemoDetails.files, (f) => ({ id: f.id })),
        },
        () => {},
        handleError,
        () => {},
      )

      try {
        if (filesToUpload.length > 0) {
          await Promise.all(
            filesToUpload.map(async ({ file }) => {
              const formData = new FormData()
              formData.append('file', file)

              await createCreditMemoFile(
                dealerId,
                data.billingCycle.id,
                creditMemoDetails.id,
                formData,
                () => {},
                () => {},
                () => {},
              )
            }),
          )
        }

        handleSuccess('Credit memo successfully updated.')
        setLoading(false)
        closeModal()
      } catch (error) {
        handleError('Error uploading file(s). Please try again')
        setLoading(false)
      }
    }
  }

  let title

  if (readOnly) {
    title = 'View Credit Memo'
  } else if (creditMemoDetails.id) {
    title = 'Edit Credit Memo'
  } else {
    title = 'Add Credit Memo'
  }

  const actions = [
    {
      type: 'cancel',
      label: 'Cancel',
      onClick: closeModal,
    },
  ]

  if (!readOnly) {
    actions.push({
      type: 'submit',
      label: creditMemoDetails.id ? 'Save' : 'Create Memo and Add Details',
      onClick: handleSubmit(onSubmit),
    })
  }

  return (
    <Modal
      open
      title={title}
      loading={loading || loadingBillingCycles}
      width="large"
      fullWidthButtons={false}
      content={
        <form onSubmit={handleSubmit(onSubmit)} className="my-8 flex flex-col gap-4">
          <section>
            <h2 className="text-charcoal-600 mb-4 text-lg font-bold leading-5">General</h2>
            <div className="mb-4 flex flex-col gap-4 sm:flex-row">
              <TextInput
                data-testid="memoId"
                disabled={readOnly}
                error={errors.memoId && 'This field is required'}
                label="Memo ID"
                inputStyles="uppercase"
                id="memoId"
                name="memoId"
                placeholder=""
                required
                fullWidth
                {...register('memoId', { required: true })}
              />

              <Controller
                name="memoAmount"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                defaultValue=""
                render={({ field: { value, onChange, ...field } }) => (
                  <TextInput
                    data-testid="creditMemoAmount"
                    {...field}
                    disabled={readOnly}
                    error={errors.memoAmount && errors.memoAmount.message}
                    label="Memo Amount"
                    id="memoAmount"
                    placeholder=""
                    fullWidth
                    currency
                    prefix="$"
                    className="text-base"
                    value={value}
                    onChange={(e) => {
                      const updatedValue = verifyCurrencyInputValue(e, setError, clearErrors)

                      // Update the form state with the raw value without moving the cursor
                      if (updatedValue) onChange(updatedValue)
                    }}
                  />
                )}
              />

              <Controller
                name="date"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    disabled={loading || readOnly}
                    dataTestId="date"
                    error={
                      errors.agesOut
                        ? { message: errors.agesOut.message || 'This field is required' }
                        : null
                    }
                    label="Date"
                    onChange={onChange}
                    show={showDatePicker}
                    setShow={setShowDatePicker}
                    value={value}
                    name="Date"
                  />
                )}
                rules={{
                  required: true,
                }}
              />

              <Controller
                name="billingCycle"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    dataTestId="billingCycle"
                    name="billingCycle"
                    id="billingCycle"
                    label="Month"
                    options={billingCycles.map((cycle) => ({
                      ...cycle,
                      disabled: cycle.state !== 'In Progress' && isWpcAdmin,
                    }))}
                    value={value}
                    disabled={billingCycles.length === 0 || readOnly}
                    className="min-h-[46px] min-w-[200px] border-none"
                    inputTextClassName="text-charcoal-950 text-base font-medium"
                    error={errors.billingCycle && 'This field is required'}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </section>

          {(creditMemoDetails.id || readOnly) && (
            <div className="flex flex-col gap-4">
              <section>
                <div className="flex items-center justify-between">
                  <h2 className="text-charcoal-600 mb-4 text-lg font-bold leading-5">Add Backs</h2>
                  <span className="text-charcoal-950 text-lg">
                    {formatCurrency(addBacksTotal, false)}
                  </span>
                </div>

                <div>
                  {addBacksFields.map((row, index) => (
                    <div key={row.id} className="mb-4 flex items-center justify-between gap-4">
                      <div className="flex w-full items-center gap-4">
                        <div className="w-full sm:w-1/3">
                          <Controller
                            name={`addBacks.${index}.amount`}
                            control={control}
                            defaultValue={row.amount || ''}
                            render={({ field: { value, onChange, ...field } }) => (
                              <TextInput
                                {...field}
                                disabled={readOnly}
                                data-testid={`addBackAmount${index}`}
                                label="Amount"
                                id={`addBacks.${index}.amount`}
                                placeholder=""
                                fullWidth
                                currency
                                prefix="$"
                                className="text-base"
                                value={value}
                                onChange={(e) => {
                                  const updatedValue = verifyCurrencyInputValue(
                                    e,
                                    setError,
                                    clearErrors,
                                  )

                                  // Update the form state with the raw value without moving the cursor
                                  if (updatedValue) onChange(updatedValue)
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="w-full sm:w-2/3">
                          <Controller
                            control={control}
                            name={`addBacks.${index}.description`}
                            defaultValue={row.description || ''}
                            render={({ field }) => (
                              <TextInput
                                {...field}
                                disabled={readOnly}
                                data-testid="addBackDescription"
                                error={errors.memoId && 'This field is required'}
                                label="Description"
                                id={`addBacks.${index}.description`}
                                name={`addBacks.${index}.description`}
                                placeholder=""
                                required
                                fullWidth
                                {...register(`addBacks.${index}.description`, { required: true })}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => removeAddBack(index)}
                        className="mt-[20px] flex items-center rounded-lg text-center hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50"
                        aria-label="Delete Add Back"
                        disabled={readOnly}
                      >
                        <TrashIcon className="h-6 stroke-white" />
                      </button>
                    </div>
                  ))}
                </div>
                {errors?.addBacks?.length > 0 && (
                  <div
                    className="mb-4 mt-1 bg-transparent px-2 py-1 text-left"
                    aria-hidden="false"
                  >
                    <p className="text-error min-h-[24px] text-sm font-medium">
                      Amount and Description are required
                    </p>
                  </div>
                )}
                <div className="flex justify-between">
                  <Button
                    className="self-end"
                    icon={<PlusIcon className="h-5 stroke-blue-800" />}
                    label="Add Back"
                    onClick={addAddBack}
                    size="sm"
                    dataTestId="addAddBackRow"
                    outlined
                    background="bg-background"
                    type="button"
                    disabled={readOnly}
                  />
                </div>
              </section>
              <section>
                <div className="flex items-center justify-between">
                  <h2 className="text-charcoal-600 my-4 text-lg font-bold leading-5">
                    Unbillables
                  </h2>
                  <span className="text-charcoal-950 text-lg">
                    ({formatCurrency(unbillablesTotal, false)})
                  </span>
                </div>
                <div>
                  {unbillablesFields.map((unbillableField, index) => (
                    <div key={unbillableField.id} className="mb-4 flex items-center gap-4">
                      <div className="w-full sm:w-1/3">
                        <Controller
                          control={control}
                          name={`unbillables.${index}.amount`}
                          defaultValue={unbillableField.amount || ''}
                          render={({ field: { value, onChange, ...field } }) => (
                            <TextInput
                              {...field}
                              data-testid={`unbillableAmount${index}`}
                              error={errors.memoAmount && errors.memoAmount.message}
                              label="Amount"
                              id={`unbillables.${index}.amount`}
                              placeholder=""
                              fullWidth
                              currency
                              prefix="$"
                              className="text-base"
                              value={value}
                              onChange={(e) => {
                                const updatedValue = verifyCurrencyInputValue(
                                  e,
                                  setError,
                                  clearErrors,
                                )

                                // Update the form state with the raw value without moving the cursor
                                if (updatedValue) onChange(updatedValue)
                              }}
                              disabled={readOnly}
                            />
                          )}
                        />
                      </div>
                      <div className="w-full sm:w-2/3">
                        <Controller
                          control={control}
                          name={`unbillables.${index}.description`}
                          defaultValue={unbillableField.description || ''}
                          render={({ field }) => (
                            <TextInput
                              {...field}
                              disabled={readOnly}
                              data-testid={`unbillableDescription${index}`}
                              error={errors.memoId && 'This field is required'}
                              label="Description"
                              placeholder=""
                              required
                              fullWidth
                              {...register(`unbillables.${index}.description`, { required: true })}
                            />
                          )}
                        />
                      </div>

                      <button
                        type="button"
                        disabled={readOnly}
                        onClick={() => removeUnbillable(index)}
                        className="mt-[20px] flex items-center rounded-lg text-center hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50"
                        aria-label="Delete Add Back"
                      >
                        <TrashIcon className="h-6 stroke-white" />
                      </button>
                    </div>
                  ))}
                </div>
                {errors?.unbillables?.length > 0 && (
                  <div
                    className="mb-4 mt-1 bg-transparent px-2 py-1 text-left"
                    aria-hidden="false"
                  >
                    <p className="text-error min-h-[24px] text-sm font-medium">
                      Amount and Description are required
                    </p>
                  </div>
                )}
                <div className="flex justify-between">
                  <Button
                    className="self-end"
                    disabled={readOnly}
                    icon={<PlusIcon className="h-5 stroke-blue-800" />}
                    label="Unbillable"
                    onClick={() => addUnbillable({ name: '', amount: '' })}
                    size="sm"
                    dataTestId="addUnbillableRow"
                    outlined
                    background="bg-background"
                    type="button"
                  />
                </div>
              </section>
              <section>
                <h2 className="text-charcoal-600 my-4 text-lg font-bold leading-5">Attachments</h2>
                <div className="flex gap-4">
                  <div className="bg-white-light  flex-1 rounded-md border-[1px]">
                    <div className="p-4">
                      <div className="border-[1px] border-dotted border-blue-500 px-2">
                        <FileUploader
                          label="Upload Files"
                          disabled={readOnly}
                          autoSave
                          acceptedFileTypes={[
                            'image/*',
                            'application/pdf',
                            'application/vnd.ms-excel',
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          ]}
                          maxFiles={1}
                          id="file"
                          type="manual"
                          className="bg-white-light min-h-130 mb-0"
                          handleUploadToServer={async (file) => {
                            setFilesToUpload((prevFiles) =>
                              prevFiles.concat({ file, id: `${Date.now()}-${file.name}` }),
                            )
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1">
                    {creditMemoDetails.files?.length === 0 && filesToUpload.length === 0 ? (
                      <span className="text-charcoal-950 text-sm">No Files Uploaded.</span>
                    ) : (
                      <ul>
                        {_.concat(creditMemoDetails.files, filesToUpload).map((file) => (
                          <li key={file.id} className="flex justify-between gap-4">
                            {file.creditMemo ? (
                              <a
                                href={file.file}
                                target="_blank"
                                className="flex text-sm text-blue-800"
                              >
                                <DocumentCheckIcon className="mr-2 h-5 w-5" />
                                <p>{file.displayName}</p>
                              </a>
                            ) : (
                              <div className="flex">
                                <DocumentCheckIcon className="text-charcoal-950 mr-2 h-5 w-5" />
                                <p className="text-charcoal-950 text-sm">{file.file.name}</p>
                              </div>
                            )}

                            <button
                              type="button"
                              disabled={readOnly}
                              onClick={() => {
                                // If this is for an existing file (already uploaded and saved), remove it from the credit memo
                                if (_.find(creditMemoDetails.files, (f) => f.id === file.id)) {
                                  setCreditMemoDetails((prevMemo) => ({
                                    ...prevMemo,
                                    files: prevMemo.files.filter((f) => f.id !== file.id),
                                  }))
                                }
                                // Otherwise, just remove it from the list of files to save
                                else {
                                  setFilesToUpload((prevFiles) =>
                                    prevFiles.filter((f) => f.id !== file.id),
                                  )
                                }
                              }}
                              className="flex items-center rounded-lg text-center hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50"
                              aria-label={`Delete ${file.file.name}`}
                            >
                              <TrashIcon className="h-6 stroke-white" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </section>
              <section className="flex justify-end">
                <span className="text-lg font-semibold">
                  Billing Total:{' '}
                  {formatCurrency(
                    readOnly
                      ? creditMemo.totalAmount
                      : Number(memoAmount) + addBacksTotal - unbillablesTotal,
                    false,
                  )}
                </span>
              </section>
            </div>
          )}
        </form>
      }
      onClose={() => {}}
      actions={actions}
    />
  )
}
export default CreditMemoModal

CreditMemoModal.propTypes = {
  dealerId: PropTypes.string,
  billingCycle: PropTypes.object,
  isEditMode: PropTypes.bool,
  creditMemo: PropTypes.object,
  billingCycles: PropTypes.array,
  loadingBillingCycles: PropTypes.bool,
  readOnly: PropTypes.bool,
  closeModal: PropTypes.func,
}
