/**
 * For a created Repair Order, all fields are autosave and must persist. To ensure data integrity
 * and continuity in user interactions, we explicitly track and update using `prevState`.
 * This approach handles successive changes, accommodating immediate, incremental
 * autosaves without losing interim user inputs or causing race conditions. All autosave
 * fields utilize this pattern to ensure data consistency.
 */

import { ChevronLeftIcon, PlusIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { AutoCompleteCombobox } from '../../components/AutoCompleteCombobox'
import { Button } from '../../components/Button'
import { CommentsIcon } from '../../components/CommentsIcon'
import { DatePicker } from '../../components/DatePicker'
import { Modal } from '../../components/Modal'
import { PencilIcon } from '../../components/PencilIcon'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'
import { Toggle } from '../../components/Toggle'
import DeleteRepairOrderModal from './DeleteRepairOrderModal'
import LineItemModal from './LineItemModal'
import ReopenRepairOrderModal from './ReopenRepairOrderModal'
import RepairOrderChat from './RepairOrderChat'
import RepairOrderLineItem from './RepairOrderLineItem'
import RepairOrderModal from './RepairOrderModal'
import ResolveRepairOrderModal from './ResolveRepairOrderModal'

// Utils & Service
import { getDealer } from '../../services/dealer.service'
import { getManufacturerActions } from '../../services/manufacturers.service'
import {
  createRepairOrder,
  getRepairOrder,
  getRepairOrderAdvisors,
  getRepairOrderTechnicians,
  updateRepairOrder,
} from '../../services/repairOrders.service'
import { toast, verifyCurrencyInputValue } from '../../utils/helpers'

// Stores
import { NotificationStoreContext } from '../../stores/NotificationStore'
import { UserStoreContext } from '../../stores/UserStore'

const RepairOrder = observer(() => {
  // Context
  const navigate = useNavigate()
  const { dealerId, id } = useParams()
  const { isDealerUser, isReadOnlyWpcUser } = useContext(UserStoreContext)
  const { unreadNotificationsCount } = useContext(NotificationStoreContext)

  // State
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingCreateRepairOrder, setLoadingCreateRepairOrder] = useState(false)
  const [loadingReturn, setLoadingReturn] = useState(false)
  const [updatedDealer, setUpdatedDealer] = useState(null)
  const [updatedRepairOrder, setUpdatedRepairOrder] = useState(null)
  const [isRepairOrderModalOpen, setIsRepairOrderModalOpen] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showDateAddedPicker, setShowDateAddedPicker] = useState(false)
  const [allAdvisors, setAllAdvisors] = useState([])
  const [filteredAdvisors, setFilteredAdvisors] = useState([])
  const [advisorQuery, setAdvisorQuery] = useState('')
  const [isLineItemModalOpen, setIsLineItemModalOpen] = useState(false)
  const [lineItems, setLineItems] = useState([])
  const [allTechnicians, setAllTechnicians] = useState([])
  const [actionOptions, setActionOptions] = useState([])
  const [defaultTechnician, setDefaultTechnician] = useState('')
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false)
  const [isResolveRepairOrderModalVisible, setIsResolveRepairOrderModalVisible] = useState(false)
  const [isReopenRepairOrderModalVisible, setIsReopenRepairOrderModalVisible] = useState(false)
  const [isDeleteRepairOrderModalVisible, setIsDeleteRepairOrderModalVisible] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [selectedLineItem, setSelectedLineItem] = useState(null)
  const [transferrableStatuses, setTransferrableStatuses] = useState({})

  // Refs
  const initialLoadRef = useRef(true)

  const handleSuccess = (message) => toast(message, 'success')
  const handleError = (message) => toast(message, 'error')

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit: handleCreateSubmit,
    register,
    watch,
    setError: setFormError,
    setValue,
  } = useForm({
    defaultValues: {
      roNumber: '',
      claimAmount: 0,
      advisor: '',
      agesOut: null,
      isAgesOutOverridden: false,
      timeClockTriggerDate: null,
      isOpen: true,
      isDateAddedOverridden: false,
    },
  })

  const getUpdatedRepairOrder = async (silent = false) => {
    let roSetLoading = setLoading
    if (silent) {
      roSetLoading = () => {}
    }
    const repairOrder = await getRepairOrder(dealerId, id, roSetLoading, setError)

    if (repairOrder) {
      setLineItems(repairOrder.lineItems)
      setUpdatedRepairOrder(repairOrder)

      if (repairOrder.lineItems.length > 0) {
        const actions = await getManufacturerActions(repairOrder.manufacturer, repairOrder.isOpen)
        if (actions) setActionOptions(actions)

        const technicians = await getRepairOrderTechnicians(dealerId)
        if (technicians) setAllTechnicians(technicians)

        setDefaultTechnician(repairOrder.lineItems[0].technician || '')
      }
    }
  }

  useEffect(() => {
    setError(null)

    const getUpdatedData = async () => {
      setLoading(true)

      const dealer = await getDealer(dealerId, () => {}, setError)
      if (dealer) setUpdatedDealer(dealer)

      const advisors = await getRepairOrderAdvisors(dealerId)
      if (advisors) setAllAdvisors(advisors)

      if (id) await getUpdatedRepairOrder()

      setLoading(false)
    }

    getUpdatedData()
  }, [dealerId, id])

  useEffect(() => {
    if (advisorQuery.length >= 2) {
      const lowerCaseQuery = advisorQuery.toLowerCase()

      setFilteredAdvisors(
        allAdvisors.filter((person) => person.toLowerCase().includes(lowerCaseQuery)),
      )
    } else {
      setFilteredAdvisors([])
    }
  }, [advisorQuery, allAdvisors])

  useEffect(() => {
    if (updatedRepairOrder) {
      // Open the chat on page mount on larger screens
      if (window.innerWidth > 1024) {
        setIsChatOpen(true)
      }
      if (window.innerWidth < 768) {
        setIsMobile(true)
      }
    }
  }, [updatedRepairOrder])

  useEffect(() => {
    // Skip the first render since the other useEffect will handle it
    if (initialLoadRef.current) {
      initialLoadRef.current = false
      return
    }

    // Only get updated repair order data if we are viewing a created one
    if (id) getUpdatedRepairOrder(true)
  }, [unreadNotificationsCount])

  // Set the screen size for the mobile view
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // Create an event listener for resizing the window
  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {
    if (isChatOpen) {
      // Prevent scrolling on the body when chat is open
      document.body.style.overflow = 'hidden'
    } else {
      // Allow scrolling again when chat is closed
      document.body.style.overflow = ''
    }

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = ''
    }
  }, [isChatOpen])

  // Render tag for repair order review state
  const repairOrderState = updatedRepairOrder?.state

  const configureReviewTagDetails = () => {
    // Configuration mapping states and user types to their details
    const tagConfig = {
      'Dealer Review': {
        dealer: { text: 'Requires Review', styling: 'text-gold-dark' },
        wpc: { text: 'In Dealer Review', styling: 'text-blue-900' },
      },
      'WPC Review': {
        dealer: { text: 'In WPC Review', styling: 'text-blue-900' },
        wpc: { text: 'Requires Review', styling: 'text-gold-dark' },
      },
    }

    // Determine user type key
    const userType = isDealerUser ? 'dealer' : 'wpc'

    // Return the corresponding configuration or a default
    return tagConfig[repairOrderState]?.[userType] || { text: '', styling: '' }
  }

  const { text: reviewTagText, styling: reviewTagStyling } = configureReviewTagDetails()

  const renderReviewTag = (text, classNames) => {
    if (!text) return null

    return (
      <div className={`flex items-center rounded-full bg-[#ffffff] px-2 py-1 ${classNames}`}>
        <span className="text-xs font-semibold uppercase leading-tight">{text}</span>
      </div>
    )
  }

  // Check if the RO is transferrable
  const canTransferRepairOrder = useMemo(
    () => Object.values(transferrableStatuses).every((status) => status === true),
    [transferrableStatuses],
  )

  const handleTransferrableChange = useCallback((lineItemId, isTransferrable) => {
    setTransferrableStatuses((prevStatuses) => ({
      ...prevStatuses,
      [lineItemId]: isTransferrable,
    }))
  }, [])

  // Check if every line item is resolved or written off
  const areAllLineItemsResolvedOrWrittenOff = useMemo(
    () =>
      lineItems.every(
        (item) => item.state === 'Resolved' || item.state === 'Resolved - Written Off',
      ),
    [lineItems],
  )

  // Determine if the "Resolve RO" button should be visible
  const isResolveROVisible = !isDealerUser && areAllLineItemsResolvedOrWrittenOff

  // Determine if the "Return" button should be visible
  const isReturnButtonVisible =
    (repairOrderState === 'Dealer Review' && isDealerUser) ||
    (!areAllLineItemsResolvedOrWrittenOff && (!isDealerUser || repairOrderState !== 'WPC Review'))

  const renderRepairOrderDateField = () => {
    const isOpen = watch('isOpen')
    const isAgesOutOverridden = watch('isAgesOutOverridden')
    const trigger = updatedDealer?.manufacturer.timeClockTrigger

    if (isOpen && !isAgesOutOverridden && trigger === 'RO close date') {
      return (
        <span className="mb-6 text-center text-xs font-light italic leading-5 tracking-[1.25px]">
          For this manufacturer, all open Repair Orders will age out 30 days after the date added
          to the Outstanding List.{' '}
        </span>
      )
    }

    const name = isAgesOutOverridden ? 'agesOut' : 'timeClockTriggerDate'

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          let errorMessage = ''
          if (isAgesOutOverridden && errors.agesOut) {
            errorMessage = errors.agesOut.message || 'This field is required'
          } else if (!isAgesOutOverridden && errors.timeClockTriggerDate) {
            errorMessage = errors.timeClockTriggerDate.message || 'This field is required'
          }

          return (
            <DatePicker
              disabled={loading}
              dataTestId="timeClockTriggerDate"
              error={errorMessage ? { message: errorMessage } : null}
              label={
                isAgesOutOverridden
                  ? 'Age Out Date Override (MM/DD/YYYY)'
                  : `${trigger} (MM/DD/YYYY)`
              }
              name={name}
              onChange={onChange}
              show={showDatePicker}
              setShow={setShowDatePicker}
              value={value}
            />
          )
        }}
        rules={{
          required: true,
        }}
      />
    )
  }

  /**
   * Formats a date or calculates days from `targetDate` to today.
   *
   * @param {string|Date} targetDate - The date to process.
   * @param {string} label - Label to prepend to output.
   * @param {boolean} formatDays - If true, returns day count; if false, formats date as 'MM/DD/YYYY'.
   *
   * Returns `${label} N/A` if `targetDate` is invalid. Otherwise, returns either a formatted date or day count.
   */
  const formatDate = (targetDate, label, formatDays = false) => {
    if (!targetDate || !dayjs(targetDate).isValid()) {
      return `${label} N/A`
    }

    if (formatDays) {
      const days = dayjs().startOf('day').diff(dayjs(targetDate).startOf('day'), 'day')
      return `${label} ${days}`
    }
    return `${label} ${dayjs(targetDate).format('MM/DD/YYYY')}`
  }

  /**
   * Determines the appropriate label for the date field.  If the Age Out Date has
   * been overridden, it returns 'Age Out Date Override'. If the manufacturer's time
   * clock trigger is 'RO close date' and the RO is still open, it returns
   * 'Age Out Date'. Otherwise, it returns the time clock trigger label.
   *
   * @returns {string} - The appropriate label for the date field.
   */
  const getDateFieldLabel = () => {
    if (updatedRepairOrder?.isAgesOutOverridden) {
      return 'Age Out Date Override'
    }
    if (
      updatedDealer?.manufacturer.timeClockTrigger === 'RO close date' &&
      updatedRepairOrder?.isOpen
    ) {
      return 'Age Out Date'
    }
    return updatedDealer?.manufacturer.timeClockTrigger
  }

  /**
   * Determines the appropriate value for the date field.
   * If the Age Out Date has been overridden, it returns
   * the overridden Age Out Date. If the manufacturer's time
   * clock trigger is 'RO close date' and the RO is still open, it returns
   * the Age Out Date.  Otherwise, it returns the time clock trigger date.
   *
   * @returns {string|Date} - The appropriate date value for the field.
   */
  const getDateFieldValue = () => {
    if (
      updatedRepairOrder?.isAgesOutOverridden ||
      (updatedDealer?.manufacturer.timeClockTrigger === 'RO close date' &&
        updatedRepairOrder?.isOpen)
    ) {
      return updatedRepairOrder?.agesOut
    }
    return updatedRepairOrder?.timeClockTriggerDate
  }

  const isMainContentVisible = !(isMobile && isChatOpen)

  const isDealerReview = repairOrderState === 'Dealer Review'
  const isWpcReview = repairOrderState === 'WPC Review'
  const isRepairOrderResolved = repairOrderState === 'Resolved'

  return (
    <div
      className={mergeClassNames(
        'flex h-full w-full',
        isMainContentVisible ? 'flex-row' : 'flex-col',
      )}
    >
      <div
        className={mergeClassNames(
          'bg-background flex w-full flex-row overflow-y-auto px-2 pb-12 pt-6 sm:px-8 lg:px-10',
          isMainContentVisible ? 'h-full' : 'h-auto',
        )}
      >
        <StateContainer error={error} loading={loading}>
          <div className="flex h-full flex-col transition-all duration-300 ease-in-out">
            {/* CHAT AND NAVIGATION */}
            <div className="mb-4 flex flex-col items-center justify-between gap-4 sm:flex-row">
              <button
                className="flex flex-row items-center gap-2"
                onClick={() => navigate(`/dealer/${dealerId}/detail`)}
                type="button"
              >
                <ChevronLeftIcon className="h-5" />
                <span className="text-charcoal-900 text-xs font-bold uppercase leading-5 tracking-[3px] sm:text-sm">
                  Back to Outstanding List
                </span>
              </button>

              {updatedRepairOrder && (
                <Button
                  icon={
                    <CommentsIcon
                      className="h-6 fill-white"
                      unread={updatedRepairOrder.hasUnreadComments}
                    />
                  }
                  label={isChatOpen ? 'Hide Chat' : 'Show Chat'}
                  onClick={() => setIsChatOpen(!isChatOpen)}
                  size="sm"
                  dataTestId="chatToggle"
                />
              )}
            </div>

            {isMainContentVisible && (
              <>
                {/* MAIN CONTENT */}
                <div className="mb-4 flex w-full flex-col justify-between gap-2 sm:flex-row">
                  {updatedDealer && (
                    <div className="flex flex-col gap-3">
                      <span className="text-charcoal-900 text-lg font-bold">
                        {updatedDealer?.name}
                      </span>

                      <div className="text-charcoal-900 flex flex-col gap-0.5 text-sm font-medium leading-5">
                        <span>WPC Dealer ID: {updatedDealer.code?.replace('.0', '')}</span>
                        {updatedDealer.group && (
                          <span>Group Name: {updatedDealer.group?.name}</span>
                        )}
                        {updatedDealer.manufacturer && (
                          <span>Manufacturer: {updatedDealer.manufacturer?.name}</span>
                        )}
                      </div>
                    </div>
                  )}

                  {updatedRepairOrder && (
                    <div className="flex flex-col gap-3 sm:text-end">
                      <div className="flex flex-col">
                        <span className="text-charcoal-900 text-lg font-bold">
                          Ages Out: {dayjs(updatedRepairOrder.agesOut).format('MM/DD/YYYY')}
                        </span>
                        <span className="text-charcoal-800 text-xs italic">
                          3 business days prior to Manufacturer guideline
                        </span>
                      </div>

                      <div className="text-charcoal-900 flex flex-col gap-0.5 text-sm font-medium leading-5">
                        <span>
                          Date Added to Outstanding List:{' '}
                          {dayjs(updatedRepairOrder.createdAt).format('MM/DD/YYYY')}
                        </span>

                        {isRepairOrderResolved ? (
                          <span>
                            {formatDate(updatedRepairOrder.resolvedAt, 'Days on List:', true)}
                          </span>
                        ) : (
                          <span>
                            {formatDate(
                              updatedRepairOrder.createdAt,
                              'Days on Outstanding List:',
                              true,
                            )}
                          </span>
                        )}

                        {isRepairOrderResolved ? (
                          <span>{formatDate(updatedRepairOrder.resolvedAt, 'Resolved On:')}</span>
                        ) : (
                          <span>
                            {formatDate(updatedRepairOrder.lastUpdatedOn, 'Last Updated:')}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {updatedRepairOrder ? (
                  <>
                    <div className="bg-brownGray-100 flex w-full flex-col gap-4 rounded-2xl px-6 py-4">
                      <div className="flex flex-row items-center justify-between">
                        <div className="flex items-center justify-center">
                          <span className="text-charcoal-900 text-sm font-bold leading-5">
                            General
                          </span>

                          {!isDealerUser && !isRepairOrderResolved && (
                            <button
                              aria-label={`Edit Repair Order: ${updatedRepairOrder.roNumber}`}
                              onClick={() => {
                                setIsRepairOrderModalOpen(true)
                              }}
                              className="ml-1 inline-flex items-center rounded-lg p-2 text-center hover:bg-gray-100 disabled:cursor-not-allowed"
                              type="button"
                              disabled={isReadOnlyWpcUser}
                              data-testid="editRepairOrder"
                            >
                              <PencilIcon className="h-4 stroke-white" />
                            </button>
                          )}
                        </div>

                        <div className="flex flex-col items-center justify-center gap-2 sm:flex-row">
                          {renderReviewTag(reviewTagText, reviewTagStyling)}
                          <div
                            className={`rounded-full px-3 py-1 ${
                              updatedRepairOrder.isOpen ? 'bg-blue-light' : 'bg-gray-300'
                            } flex w-full flex-col text-center sm:w-auto sm:flex-row sm:items-center sm:justify-center`}
                          >
                            <span
                              className={`text-xs font-semibold leading-tight ${
                                updatedRepairOrder.isOpen ? 'text-blue-950' : 'text-gray-700'
                              }`}
                            >
                              {updatedRepairOrder.isOpen ? 'OPEN' : 'CLOSED'}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`flex h-full w-full flex-col justify-between gap-4 md:flex-row ${
                          isChatOpen ? 'flex-wrap' : ''
                        }`}
                      >
                        <TextInput
                          data-testid="roNumber"
                          disabled
                          label="REPAIR ORDER #"
                          inputStyles="uppercase"
                          id="roNumber"
                          fullWidth
                          value={updatedRepairOrder.roNumber}
                        />

                        <TextInput
                          data-testid="claimAmount"
                          disabled
                          label="Claim Amount"
                          id="claimAmount"
                          infoTooltip={
                            updatedRepairOrder.isOpen
                              ? 'Claim amount is the total of all Line Items and any additional dollar amount held up.'
                              : 'Claim amount is the total of all unresolved Line Items.'
                          }
                          fullWidth
                          value={`${Number(
                            updatedRepairOrder.totalUnresolvedClaimAmount,
                          ).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}`}
                        />

                        <TextInput
                          data-testid="advisor"
                          disabled
                          label="ADV #"
                          id="advisor"
                          fullWidth
                          value={updatedRepairOrder.advisor}
                        />

                        <TextInput
                          data-testid="timeClockTriggerDate"
                          disabled
                          label={getDateFieldLabel()}
                          id="timeClockTriggerDate"
                          fullWidth
                          value={dayjs(getDateFieldValue()).format('MM/DD/YYYY')}
                        />
                      </div>
                    </div>

                    <div className="w-full pt-8">
                      {lineItems.map((lineItem) => (
                        <RepairOrderLineItem
                          actionOptions={actionOptions}
                          key={lineItem.id}
                          lineItem={lineItem}
                          allTechnicians={allTechnicians}
                          defaultTechnician={defaultTechnician}
                          dealerId={dealerId}
                          repairOrderId={updatedRepairOrder.id}
                          setDefaultTechnician={setDefaultTechnician}
                          isFirstLineItem={lineItem.id === lineItems[0].id}
                          isRepairOrderResolved={isRepairOrderResolved}
                          openEditModal={(item) => {
                            setSelectedLineItem(item)
                            setIsLineItemModalOpen(true)
                          }}
                          getUpdatedRepairOrder={getUpdatedRepairOrder}
                          onTransferrableChange={(isTransferrable) =>
                            handleTransferrableChange(lineItem.id, isTransferrable)
                          }
                          isDealerReview={isDealerReview}
                        />
                      ))}
                    </div>

                    {!isRepairOrderResolved && (
                      <>
                        {!isDealerUser && (
                          <Button
                            className="mb-8 self-end"
                            icon={<PlusIcon className="h-5 stroke-white" />}
                            label="Add Line Item"
                            onClick={() => setIsLineItemModalOpen(true)}
                            size="sm"
                            disabled={isReadOnlyWpcUser}
                            dataTestId="addLineItem"
                          />
                        )}
                        {/* Show Return to Dealer / Return to WPC button if conditions are met */}
                        {isReturnButtonVisible && (
                          <Button
                            className="mx-auto"
                            dataTestId="returnRepairOrder"
                            label={isWpcReview ? 'Return to Dealer' : 'Return to WPC'}
                            onClick={() => setIsConfirmationModalVisible(true)}
                            size="sm"
                            type="button"
                            disabled={
                              lineItems.length === 0 ||
                              !canTransferRepairOrder ||
                              isReadOnlyWpcUser
                            }
                          />
                        )}
                        {/* Show Resolve RO button if conditions are met */}
                        {isResolveROVisible && (
                          <Button
                            className="mx-auto"
                            dataTestId="resolveRepairOrder"
                            label="Resolve RO"
                            onClick={() => setIsResolveRepairOrderModalVisible(true)}
                            size="sm"
                            type="button"
                            disabled={isReadOnlyWpcUser}
                          />
                        )}
                      </>
                    )}
                    {/* Show Reopen RO button if RO is Resolved and user is WPC */}
                    {isRepairOrderResolved && !isDealerUser && (
                      <Button
                        className="mx-auto"
                        label="Reopen RO"
                        onClick={() => setIsReopenRepairOrderModalVisible(true)}
                        size="sm"
                        type="button"
                        disabled={isReadOnlyWpcUser}
                      />
                    )}
                    <ResolveRepairOrderModal
                      repairOrder={updatedRepairOrder}
                      isOpen={isResolveRepairOrderModalVisible}
                      setIsOpen={setIsResolveRepairOrderModalVisible}
                      dealerId={dealerId}
                      repairOrderId={id}
                    />
                    <ReopenRepairOrderModal
                      isOpen={isReopenRepairOrderModalVisible}
                      setIsOpen={setIsReopenRepairOrderModalVisible}
                      repairOrder={updatedRepairOrder}
                      lineItems={lineItems}
                      repairOrderId={id}
                      dealerId={dealerId}
                      getUpdatedRepairOrder={getUpdatedRepairOrder}
                      defaultTechnician={defaultTechnician}
                    />
                    <DeleteRepairOrderModal
                      isOpen={isDeleteRepairOrderModalVisible}
                      setIsOpen={setIsDeleteRepairOrderModalVisible}
                      repairOrderId={id}
                      dealerId={dealerId}
                    />

                    <LineItemModal
                      dealerId={dealerId}
                      defaultTechnician={defaultTechnician}
                      isOpen={isLineItemModalOpen}
                      setIsOpen={setIsLineItemModalOpen}
                      repairOrderId={id}
                      getUpdatedRepairOrder={getUpdatedRepairOrder}
                      lineItem={selectedLineItem}
                      setOpen={setIsLineItemModalOpen}
                      closeModal={() => {
                        setIsLineItemModalOpen(false)
                        setSelectedLineItem(null)
                      }}
                    />

                    <RepairOrderModal
                      getUpdatedRepairOrder={getUpdatedRepairOrder}
                      closeModal={() => setIsRepairOrderModalOpen(false)}
                      isOpen={isRepairOrderModalOpen}
                      repairOrder={updatedRepairOrder}
                      allAdvisors={allAdvisors}
                      setIsOpen={setIsRepairOrderModalOpen}
                      dealer={updatedDealer}
                      setIsDeleteModalOpen={setIsDeleteRepairOrderModalVisible}
                    />

                    <Modal
                      open={isConfirmationModalVisible}
                      title="Are you sure?"
                      setOpen={setIsConfirmationModalVisible}
                      content={
                        <div className="my-8 flex h-full items-center justify-center">
                          <p className="text-center">
                            {`Please confirm that you'd like to return the repair order to ${
                              isWpcReview ? 'Dealer' : 'WPC'
                            }.`}
                          </p>
                        </div>
                      }
                      actions={[
                        {
                          type: 'submit',
                          label: 'Confirm',
                          onClick: async () => {
                            // Toggle the state based on the current state
                            const payload = isWpcReview
                              ? { markAsDealerReview: true }
                              : { markAsWpcReview: true }

                            await updateRepairOrder(
                              dealerId,
                              id,
                              payload,
                              setLoadingReturn,
                              handleError,
                              () => {
                                handleSuccess(
                                  `Repair order returned to ${isWpcReview ? 'Dealer' : 'WPC'}.`,
                                )
                                setIsConfirmationModalVisible(false)
                                getUpdatedRepairOrder()
                                if (isWpcReview || (isDealerReview && isDealerUser)) {
                                  navigate(`/dealer/${dealerId}/detail`)
                                }
                              },
                            )
                          },
                        },
                        { type: 'cancel', label: 'Cancel' },
                      ]}
                      loading={loadingReturn}
                    />

                    <div className="h-[20px] flex-none" />
                  </>
                ) : (
                  <div className="bg-brownGray-100 flex flex-col gap-2 rounded-2xl px-8 pt-8 md:flex-row md:gap-16">
                    <div className="flex w-full flex-col gap-4">
                      <div className="flex w-full flex-col gap-4">
                        <span className="text-charcoal-900 text-sm font-bold leading-5">
                          General
                        </span>

                        <div className="flex h-full w-full flex-col space-y-5">
                          <TextInput
                            data-testid="roNumber"
                            disabled={loadingCreateRepairOrder}
                            error={errors.roNumber && 'This field is required'}
                            label="REPAIR ORDER #"
                            inputStyles="uppercase"
                            id="roNumber"
                            name="roNumber"
                            placeholder=""
                            required
                            fullWidth
                            autoFocus
                            {...register('roNumber', { required: true })}
                          />

                          <AutoCompleteCombobox
                            id="advisor-id"
                            dataTestId="advisor"
                            disabled={loadingCreateRepairOrder}
                            label="ADV #"
                            value={watch('advisor')}
                            inputClassName="font-medium text-black"
                            onComboboxChange={(value) => {
                              setValue('advisor', value)
                              clearErrors('advisor')
                            }}
                            options={filteredAdvisors}
                            error={errors.advisor?.message}
                            onComboboxInputChange={(event) => {
                              const inputValue = event.target.value
                              setAdvisorQuery(inputValue)
                              setValue('advisor', inputValue, { shouldValidate: true })
                              clearErrors('advisor')
                            }}
                          />

                          {watch('isOpen') && (
                            <Controller
                              name="additionalDollarsHeldUp"
                              control={control}
                              defaultValue=""
                              disabled={loadingCreateRepairOrder}
                              render={({ field: { value, onChange, ...field } }) => (
                                <TextInput
                                  data-testid="additionalDollarsHeldUp"
                                  error={
                                    errors.additionalDollarsHeldUp &&
                                    errors.additionalDollarsHeldUp.message
                                  }
                                  label="Additional Dollars Held Up"
                                  id="additionalDollarsHeldUp"
                                  placeholder=""
                                  fullWidth
                                  currency
                                  prefix="$"
                                  className="text-base"
                                  {...field}
                                  value={value}
                                  onChange={(e) => {
                                    const updatedValue = verifyCurrencyInputValue(
                                      e,
                                      setError,
                                      clearErrors,
                                    )

                                    // Update the form state with the raw value without moving the cursor
                                    if (updatedValue) onChange(updatedValue)
                                  }}
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex w-full flex-col gap-4">
                      <span className="text-charcoal-900 text-sm font-bold leading-5">
                        Age Out Date
                      </span>

                      <div className="flex h-full flex-col justify-between gap-4">
                        <div className="flex flex-col gap-4">
                          <Controller
                            name="isOpen"
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <Toggle
                                disabled={loadingCreateRepairOrder}
                                label="Open RO?"
                                name="isOpen"
                                onChange={onChange}
                                checked={value}
                                ref={ref}
                              />
                            )}
                          />

                          <Controller
                            name="isAgesOutOverridden"
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <Toggle
                                disabled={loadingCreateRepairOrder}
                                label="Requires Age Out Override"
                                name="isAgesOutOverridden"
                                onChange={onChange}
                                checked={value}
                                ref={ref}
                              />
                            )}
                          />

                          <Controller
                            name="isDateAddedOverridden"
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <Toggle
                                disabled={loadingCreateRepairOrder}
                                label="Requires Date Added Override"
                                name="isDateAddedOverridden"
                                onChange={onChange}
                                checked={value}
                                ref={ref}
                              />
                            )}
                          />
                        </div>

                        {renderRepairOrderDateField()}

                        {watch('isDateAddedOverridden') && (
                          <Controller
                            name="createdAt"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <DatePicker
                                disabled={loadingCreateRepairOrder}
                                dataTestId="createdAt"
                                error={errors.createdAt}
                                label="Date Added Override (MM/DD/YYYY)"
                                name="createdAt"
                                onChange={onChange}
                                show={showDateAddedPicker}
                                setShow={setShowDateAddedPicker}
                                value={value}
                              />
                            )}
                            rules={{
                              required: 'This field is required',
                            }}
                          />
                        )}
                      </div>

                      <div className="mt-4 flex flex-row justify-end gap-4 pb-8">
                        <Button
                          background="bg-background"
                          disabled={loadingCreateRepairOrder || isReadOnlyWpcUser}
                          label="Cancel"
                          onClick={() => navigate(`/dealer/${dealerId}/detail`)}
                          size="sm"
                        />

                        <Button
                          label="Create RO"
                          dataTestId="create-ro"
                          loading={loadingCreateRepairOrder}
                          onClick={handleCreateSubmit(async (data) => {
                            // Verify advisor
                            if (!data.advisor) {
                              setFormError('advisor', {
                                type: 'manual',
                                message: 'Please select an advisor.',
                              })
                              return
                            }

                            const payload = {
                              ...data,
                              roNumber: data.roNumber.toUpperCase(),
                            }

                            if (data.isAgesOutOverridden && data.agesOut) {
                              // Verify agesOut is a valid date
                              if (!dayjs(data.agesOut).isValid()) {
                                setFormError('agesOut', {
                                  type: 'manual',
                                  message: 'Please enter a valid date (MM/DD/YYYY).',
                                })
                                return
                              }

                              payload.agesOut = dayjs(data.agesOut).format('YYYY-MM-DD')

                              // Clear out `timeClockTriggerDate`, we only need one date
                              payload.timeClockTriggerDate = null
                            } else if (data.timeClockTriggerDate) {
                              // Verify timeClockTriggerDate is a valid date
                              if (!dayjs(data.timeClockTriggerDate).isValid()) {
                                setFormError('timeClockTriggerDate', {
                                  type: 'manual',
                                  message: 'Please enter a valid date (MM/DD/YYYY).',
                                })
                                return
                              }

                              payload.timeClockTriggerDate = dayjs(
                                data.timeClockTriggerDate,
                              ).format('YYYY-MM-DD')

                              // Clear out `agesOut`, we only need one date
                              payload.agesOut = null
                            }

                            if (data.createdAt) {
                              // Verify createdAt is a valid date
                              if (!dayjs(data.createdAt).isValid()) {
                                setFormError('createdAt', {
                                  type: 'manual',
                                  message: 'Please enter a valid date (MM/DD/YYYY).',
                                })
                                return
                              }

                              payload.createdAt = dayjs(data.createdAt).format('YYYY-MM-DD')
                            }

                            const response = await createRepairOrder(
                              dealerId,
                              payload,
                              setLoadingCreateRepairOrder,
                              (err) => {
                                if (err.roId) {
                                  handleError({
                                    ...err,
                                    linkLabel: `RO: ${payload.roNumber}`,
                                    url: `/dealer/${dealerId}/repair-order/${err.roId}`,
                                  })
                                } else {
                                  handleError(err)
                                }
                              },
                              handleSuccess,
                            )

                            if (response) {
                              navigate(`/dealer/${dealerId}/repair-order/${response.id}`)
                            }
                          })}
                          size="sm"
                          disabled={isReadOnlyWpcUser}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </StateContainer>
      </div>

      {isChatOpen && (
        <RepairOrderChat
          dealerId={dealerId}
          repairOrderId={updatedRepairOrder?.id}
          isRepairOrderResolved={isRepairOrderResolved}
        />
      )}
    </div>
  )
})

export default RepairOrder
