import { PlusIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid'
import _ from 'lodash'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { MapPinIcon } from '../../components/MapPinIcon'
import { PencilIcon } from '../../components/PencilIcon'
import { TextInput } from '../../components/TextInput'
import { TrashIcon } from '../../components/TrashIcon'
import DealerUserModal from './DealerUserModal'
import DeleteUserModal from './DeleteDealerUserModal'

// Utils & Service
import { getDealerGroups } from '../../services/dealers.service'
import { getUsers } from '../../services/user.service'
import { handlePagination, toast } from '../../utils/helpers'

const USERS_BASE_URL = '/users/'

const DealerUsers = () => {
  // State
  const [loading, setLoading] = useState(false)
  const [isDealerUserModalOpen, setIsDealerUserModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [users, setUsers] = useState([])
  const [sortedColumn, setSortedColumn] = useState('first_name')
  const [dealerGroupOptions, setDealerGroupOptions] = useState([])
  const [updateTrigger, setUpdateTrigger] = useState(0)
  const [filter, setFilter] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(20)
  const [totalRows, setTotalRows] = useState()

  // Ref
  const searchInputRef = useRef()

  const handleError = (message) => toast(message, 'error')

  useEffect(() => {
    const getDealerGroupsList = async () => {
      const response = await getDealerGroups()
      setDealerGroupOptions(response)
    }

    getDealerGroupsList()
  }, [])

  /**
   * Fetches the list of users from the given URL and updates the state with the response data.
   *
   * @param {string} url - The URL to fetch the users from.
   */
  const getUsersList = async (url) => {
    await getUsers(url, handleError, setLoading, (response) => {
      setUsers(response.results)
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
    })
  }

  /**
   * Updates the search query based on `search`.
   * @param {string} search
   */
  const updateSearch = (search) => {
    let updatedFilter = ''
    if (search) updatedFilter = `q=${search}`

    setFilter(updatedFilter)
  }

  const filterUsers = useCallback(_.debounce(updateSearch, 500), [])

  useEffect(() => {
    getUsersList(
      `${USERS_BASE_URL}?page=1&limit=${perPage}&order_by=${sortedColumn}${
        filter ? `&${filter}` : ''
      }&expand=dealer_user_profile.dealers,dealer_user_profile.dealer_groups`,
    )
  }, [filter, perPage, sortedColumn, updateTrigger])

  const renderActionsCell = (row) => (
    <div className="flex flex-row items-center gap-1">
      <button
        aria-label={`Edit ${row.firstName}`}
        onClick={() => {
          setSelectedUser(row)
          setIsDealerUserModalOpen(true)
        }}
        className="inline-flex items-center rounded-lg p-2 text-center hover:bg-gray-100"
        type="button"
      >
        <PencilIcon className="h-4 stroke-white" />
      </button>
      <button
        aria-label={`Delete ${row.firstName}`}
        onClick={() => {
          setSelectedUser(row)
          setIsDeleteModalOpen(true)
        }}
        className="inline-flex items-center rounded-lg p-2 text-center hover:bg-gray-100"
        type="button"
      >
        <TrashIcon className="h-4 stroke-white" />
      </button>
    </div>
  )

  const renderDealerLocationCell = (row) => {
    const getDealerInfo = (user) => {
      if (user.dealerUserProfile) {
        const { dealerGroups, dealers } = user.dealerUserProfile

        if (dealers && dealers.length > 0) {
          return dealers.map((dealer) => dealer.name)
        }
        return dealerGroups.map((group) => group.name)
      }

      return ['Location not provided']
    }

    return (
      <div className="flex flex-row items-center">
        <MapPinIcon className="stroke-charcoal-900 h-4 w-4 shrink-0" />
        <div className="ml-2">
          {getDealerInfo(row).map((location) => (
            <p key={`${row.id}-${location}`}>{location}</p>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="bg-background flex h-full w-full flex-col overflow-y-auto px-4 pb-12 pt-6 sm:px-6 lg:px-8">
      <div className="mb-2 flex items-center justify-between">
        <span className="text-midnight text-2xl font-semibold">Dealer Users</span>
        <Button
          className="self-end"
          icon={<PlusIcon className="h-5 stroke-white" />}
          label="Add Dealer User"
          onClick={() => {
            setSelectedUser(null)
            setIsDealerUserModalOpen(true)
          }}
          size="sm"
        />
      </div>

      <TextInput
        className="mb-4 w-72 pl-10"
        icon={<MagnifyingGlassIcon className="text-gray-dark ml-2 h-5" aria-hidden="true" />}
        id="search"
        endIcon={
          searchTerm ? (
            <button
              type="button"
              onClick={() => {
                setSearchTerm('')
                filterUsers('')
                searchInputRef.current.value = ''
              }}
            >
              <XMarkIcon className="text-gray-dark mr-2 h-5" aria-hidden="true" />
            </button>
          ) : null
        }
        name="search"
        onChange={(e) => {
          filterUsers(e.target.value)
          setSearchTerm(e.target.value)
        }}
        placeholder="Looking for something?"
        ref={searchInputRef}
        value={searchTerm}
      />

      <DataTable
        columns={[
          {
            id: 'firstName',
            name: 'Name',
            selector: (row) =>
              !row.firstName && !row.lastName
                ? 'Name not provided'
                : `${row.firstName} ${row.lastName}`,
            sortable: true,
            sortBy: 'first_name',
          },
          {
            id: 'dealerGroup',
            name: 'Dealer Locations',
            cell: renderDealerLocationCell,
            sortable: false,
          },
          {
            id: 'role',
            name: 'Role',
            selector: (row) => row?.dealerUserProfile?.role || 'N/A',
            sortBy: 'dealer_user_profile__role',
            sortable: true,
          },
          {
            id: 'lastLogin',
            name: 'Last Login',
            sortBy: 'last_login',
            sortable: true,
            selector: (row) =>
              row.lastLogin ? `${dayjs(row.lastLogin).format('MM/DD/YYYY')}` : 'N/A',
          },
          {
            id: 'actions',
            name: 'Actions',
            cell: renderActionsCell,
          },
        ]}
        data={users}
        pagination
        paginationServer
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[5, 10, 20, 30, 50]}
        paginationTotalRows={totalRows}
        progressPending={loading}
        onChangeRowsPerPage={(currentRowsPerPage) => setPerPage(currentRowsPerPage)}
        onSort={(column, direction) => {
          const d = direction === 'asc' ? '' : '-'
          setSortedColumn(`${d}${column.sortBy}`)
        }}
        onChangePage={(page) =>
          handlePagination(
            page,
            currentPage,
            perPage,
            totalRows,
            pages,
            setCurrentPage,
            getUsersList,
            `${USERS_BASE_URL}?&limit=`,
            `order_by=${sortedColumn}&expand=dealer_user_profile.dealers`,
          )
        }
        sortServer
      />

      <DeleteUserModal
        setIsOpen={setIsDeleteModalOpen}
        isOpen={isDeleteModalOpen}
        user={selectedUser}
        onDelete={() => setUpdateTrigger(updateTrigger + 1)}
      />

      {isDealerUserModalOpen && (
        <DealerUserModal
          setIsOpen={setIsDealerUserModalOpen}
          isOpen={isDealerUserModalOpen}
          user={selectedUser}
          onSave={() => setUpdateTrigger(updateTrigger + 1)}
          dealerGroupOptions={dealerGroupOptions?.map((group) => ({
            ...group,
            label: group.name,
          }))}
        />
      )}
    </div>
  )
}

export default DealerUsers
