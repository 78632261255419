import { Textarea } from '@headlessui/react'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

// Components
import { Modal } from '../../components/Modal'

// Utils & Services
import { updateBillingCycle } from '../../services/billing.service'
import { toast } from '../../utils/helpers'

/**
 *
 * RejectionModal
 *
 */
const RejectionModal = ({
  showModal,
  setShowModal,
  dealerId,
  billingCycleId = '',
  getUpdatedBillingCycle,
}) => {
  // State
  const [loading, setLoading] = useState(false)

  const handleSuccess = (message) => toast(message, 'success')
  const handleError = (message) => toast(message, 'error')

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      notesForAdmin: '',
    },
  })

  /**
   * Handles updating the billing cycle.
   * @param {object} data
   */
  const onSubmit = (data) => {
    updateBillingCycle(
      dealerId,
      billingCycleId,
      {
        ...data,
        markAsRejected: true,
      },
      setLoading,
      handleError,
      () => {
        handleSuccess('Billing cycle is rejected.')
        setShowModal(false)
        getUpdatedBillingCycle()
      },
    )
  }

  const handleClose = () => {
    reset()
    setShowModal(false)
  }

  return (
    <Modal
      open={showModal}
      title="Rejection Notes"
      setOpen={setShowModal}
      loading={loading}
      onClose={handleClose}
      content={
        <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4 flex flex-wrap">
            <label
              htmlFor="notesForAdmin"
              className="text-charcoal pb-2 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]"
            >
              Notes for Admin
            </label>
            <Textarea
              {...register('notesForAdmin', { required: 'This field is required' })}
              data-testid="notesForAdmin"
              className="border-white-light text-charcoal-900 bg-white-light my-4 min-h-[75px] w-full rounded-lg p-3"
            />

            {errors.notesForAdmin && (
              <div
                aria-hidden="false"
                className="mt-1 w-full bg-transparent px-2 py-1 text-center"
              >
                <p className="text-error min-h-[24px] text-sm font-medium">
                  {errors.notesForAdmin.message}
                </p>
              </div>
            )}
          </div>
        </form>
      }
      actions={[
        {
          type: 'submit',
          label: 'Return to Admin',
          onClick: handleSubmit(onSubmit),
        },
        { type: 'cancel', label: 'Cancel', onClick: handleClose },
      ]}
    />
  )
}

export default RejectionModal

RejectionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  dealerId: PropTypes.string.isRequired,
  billingCycleId: PropTypes.string,
  getUpdatedBillingCycle: PropTypes.func.isRequired,
}
