import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import dayjs from 'dayjs'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { Dialog } from '../../components/Dialog'
import { Select } from '../../components/Select'
import ApprovalModal from './ApprovalModal'
import CreditMemoModal from './CreditMemoModal'
import ExpandedCreditMemoRow from './ExpandedCreditMemoRow'
import RejectionModal from './RejectionModal'
import SubmitForApprovalModal from './SubmitForApprovalModal'

// Icons
import UserIcon from '../../assets/images/user_icon.svg'
import { AddIcon } from '../../components/AddIcon'
import { EyeIcon } from '../../components/EyeIcon'
import { MinusIcon } from '../../components/MinusIcon'
import { PencilIcon } from '../../components/PencilIcon'
import { TrashIcon } from '../../components/TrashIcon'

// Stores
import { UserStoreContext } from '../../stores/UserStore'

// Utils & Service
import {
  deleteCreditMemo,
  getDealerBillingCycles,
  getDealerCreditMemos,
  getOutstandingDealerBillingCycles,
  updateBillingCycle,
} from '../../services/billing.service'
import { formatCurrency, formatMonthName } from '../../utils/formatters'
import { handlePagination, toast } from '../../utils/helpers'

const CreditMemos = ({ dealerId = '' }) => {
  // Context
  const { isWpcAdmin, isWpcExecutive, isReadOnlyWpcUser } = useContext(UserStoreContext)

  // State
  const [memos, setMemos] = useState([])
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [billingCycles, setBillingCycles] = useState([])
  const [outstandingBillingCycles, setOutstandingBillingCycles] = useState([])
  const [selectedBillingCycle, setSelectedBillingCycle] = useState({})
  const [selectedCreditMemo, setSelectedCreditMemo] = useState({})
  const [sortedColumn, setSortedColumn] = useState('date')

  const [loadingOutstandingBillingCycles, setLoadingOutstandingBillingCycles] = useState(true)
  const [loadingBillingCycle, setLoadingBillingCycle] = useState(true)
  const [loadingCreditMemos, setLoadingCreditMemos] = useState(true)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingReopen, setLoadingReopen] = useState(false)

  const [showCreditModal, setShowCreditModal] = useState(false)
  const [showRejectionModal, setShowRejectionModal] = useState(false)
  const [showSubmitForApprovalModal, setShowSubmitForApprovalModal] = useState(false)
  const [showReadOnlyModal, setShowReadOnlyModal] = useState(false)
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [showReopenModal, setShowReopenModal] = useState(false)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState()
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  const MEMOS_BASE_URL = `/dealers/${dealerId}/billing-cycles/${selectedBillingCycle?.id}/credit-memos/?expand=created_by,add_backs,unbillables,files`

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  const canPerformActions =
    !(selectedBillingCycle?.state === 'Awaiting Approval' && isWpcAdmin) &&
    !isReadOnlyWpcUser &&
    selectedBillingCycle?.state !== 'Submitted to Billing' &&
    selectedBillingCycle?.state !== 'Closed'

  /**
   * Gets the updated list of credit; updates pagination.
   * @param {string} url
   */
  const getUpdatedMemos = async (url) => {
    const response = await getDealerCreditMemos(url, setLoadingCreditMemos, handleErrors)

    if (response) {
      setMemos(response.results)
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
    }
  }

  const fetchBillingCycles = async () => {
    const data = await getDealerBillingCycles(dealerId, setLoadingBillingCycle, handleErrors)

    if (data?.count > 0) {
      setBillingCycles(
        _.map(data.results, (cycle) => ({
          ...cycle,
          id: cycle.id,
          label: `${formatMonthName(cycle?.period?.month)} ${cycle?.period?.year}`,
          year: cycle?.period?.year,
          month: cycle?.period?.month,
          state: cycle.state,
        })),
      )
    } else {
      setBillingCycles([]) // Clear billing cycles if none are returned
      setMemos([]) // Clear memos since no billing cycles exist
      setSelectedBillingCycle({}) // Reset selected billing cycle
      setLoadingBillingCycle(false)
      setLoadingCreditMemos(false)
    }
  }

  const fetchOutstandingBillingCycles = async () => {
    const data = await getOutstandingDealerBillingCycles(
      dealerId,
      setLoadingOutstandingBillingCycles,
      handleErrors,
    )
    if (data?.count > 0) {
      setOutstandingBillingCycles(
        _.map(data.results, (cycle) => ({
          id: cycle.id,
          label: `${formatMonthName(cycle.period?.month)} ${cycle.period?.year}`,
          year: cycle.period?.year,
          month: cycle.period?.month,
          state: cycle.state,
        })),
      )
    } else {
      setOutstandingBillingCycles([]) // Clear billing cycles if none are returned
    }
  }

  const fetchCreditMemo = async (cycleId) => {
    if (cycleId) {
      await getUpdatedMemos(
        `${MEMOS_BASE_URL}&limit=${perPage}&page=${currentPage}&order_by=${sortedColumn}`,
      )
    }
  }

  useEffect(() => {
    setBillingCycles([])
    setSelectedBillingCycle({})
    setMemos([])

    if (dealerId) {
      setBillingCycles([]) // Clear previous billing cycles
      setSelectedBillingCycle({}) // Clear stale selected billing cycle
      setMemos([]) // Clear memos
      fetchBillingCycles() // Fetch billing cycles for the new dealer
      fetchOutstandingBillingCycles()
    } else {
      setBillingCycles([])
      setOutstandingBillingCycles([])
      setSelectedBillingCycle({})
      setMemos([])
    }
  }, [dealerId])

  useEffect(() => {
    if (billingCycles.length > 0) {
      // Find the oldest non-submitted billing cycle
      const defaultCycle = _.findLast(
        billingCycles,
        (cycle) => cycle.state !== 'Submitted to Billing' && cycle.state !== 'Closed',
      )

      if (defaultCycle) {
        setSelectedBillingCycle(defaultCycle)
      } else {
        setSelectedBillingCycle(billingCycles[0]) // Fallback to the first in the list
      }
    } else {
      setSelectedBillingCycle({}) // Reset selectedBillingCycle if no billing cycles
      setMemos([]) // Clear memos when there are no billing cycles
    }
  }, [billingCycles])

  useEffect(() => {
    if (selectedBillingCycle?.id) {
      getUpdatedMemos(
        `${MEMOS_BASE_URL}&limit=${perPage}&page=${currentPage}&order_by=${sortedColumn}`,
      )
    } else {
      setMemos([]) // Clear memos if no valid selectedBillingCycle
    }
  }, [selectedBillingCycle, currentPage, perPage, sortedColumn])

  /**
   * Determines and returns the actions that are available based on `canPerformActions`.
   * @param {object} row
   * @returns
   */
  const renderActions = (row) => {
    if (canPerformActions) {
      return (
        <div>
          <button
            aria-label={`Edit Credit Memo ${row.memoId}`}
            onClick={() => {
              setSelectedCreditMemo(row)
              setShowCreditModal(true)
            }}
            className="inline-flex items-center rounded-lg p-2 text-center hover:bg-gray-100"
            type="button"
          >
            <PencilIcon className="h-4 stroke-white" />
          </button>
          <button
            aria-label={`Delete Credit Memo ${row.memoId}`}
            onClick={() => {
              setSelectedCreditMemo(row)
              setShowDeleteDialog(true)
            }}
            className="inline-flex items-center rounded-lg p-2 text-center hover:bg-gray-100"
            type="button"
          >
            <TrashIcon className="h-4 stroke-white" />
          </button>
        </div>
      )
    }

    return (
      <button
        aria-label={`View Credit Memo ${row.memoId}`}
        onClick={() => {
          setShowReadOnlyModal(true)
          setSelectedCreditMemo(row)
          setShowCreditModal(true)
        }}
        className="inline-flex items-center rounded-lg p-2 text-center hover:bg-gray-100"
        type="button"
      >
        <EyeIcon className="h-4 stroke-white" />
      </button>
    )
  }

  /**
   * Renders the added by user.
   * @param {object} row
   */
  const renderAddedBy = (row) => (
    <div className="flex flex-row gap-1">
      <img src={UserIcon} className="mr-1" alt="" />
      {`${row.createdBy?.firstName || ''} ${row.createdBy?.lastName || ''}`}
    </div>
  )

  /**
   * Handles rendering any action buttons depending on the `status`.
   * @param {string} status
   * @param {number} selectedMonth
   */
  const renderActionButtons = (status, selectedMonth) => {
    if (!canPerformActions && !isWpcExecutive) {
      return null
    }

    switch (status) {
      case 'In Progress':
        return (
          <div className="m-auto flex gap-4 pt-8">
            <Button
              className="px-4 py-2"
              dataTestId="submitCreditMemos"
              label={`Submit ${dayjs()
                .month(selectedMonth - 1)
                .format('MMMM')} Memos for Approval`}
              onClick={() => setShowSubmitForApprovalModal(true)}
            />
          </div>
        )
      case 'Awaiting Approval':
        return (
          <div className="m-auto flex gap-4 pt-8">
            <Button
              className="px-4 py-2"
              dataTestId="rejectCreditMemos"
              label="Reject"
              outlined
              background="bg-background"
              onClick={() => setShowRejectionModal(true)}
            />
            <Button
              className="px-3 py-2"
              dataTestId="approveCreditMemos"
              label="Approve"
              onClick={() => setShowApprovalModal(true)}
            />
          </div>
        )

      case 'Submitted to Billing':
        return isWpcExecutive ? (
          <div className="m-auto pt-8">
            <Button
              className="px-4 py-2"
              dataTestId="reopenBillingMonth"
              label="Reopen Month"
              onClick={() => setShowReopenModal(true)}
              outlined
              background="bg-background"
            />
          </div>
        ) : null
      case 'Closed':
      case 'Not Started':
      default:
        return null
    }
  }

  const columns = [
    {
      name: 'Memo Date',
      selector: (row) => dayjs(row.date).format('MM/DD/YYYY'),
      sortable: true,
      sortBy: 'date',
      minWidth: '150px',
    },
    {
      name: 'Credit Memo ID',
      selector: (row) => row.memoId,
      sortable: true,
      sortBy: 'memo_id',
      minWidth: '200px',
    },
    {
      name: 'Billing Total',
      selector: (row) => formatCurrency(row.totalAmount),
      sortable: true,
      sortBy: 'total_amount',
      minWidth: '150px',
    },
    {
      name: 'Memo Amount',
      selector: (row) => formatCurrency(row.memoAmount),
      sortable: true,
      sortBy: 'memo_amount',
      minWidth: '150px',
    },
    {
      name: 'Add Backs Total',
      selector: (row) => formatCurrency(row.totalAddBacks),
      sortable: true,
      sortBy: 'total_add_backs',
      minWidth: '150px',
    },
    {
      name: 'Unbillables',
      selector: (row) => `(${formatCurrency(row.totalUnbillables)})`,
      sortable: true,
      sortBy: 'total_unbillables',
      minWidth: '150px',
    },
    {
      name: 'Added By',
      cell: (row) => (row.createdBy && renderAddedBy(row)) || 'N/A',
      sortable: true,
      sortBy: 'created_by__first_name',
      minWidth: '250px',
    },
    {
      name: 'Actions',
      cell: renderActions,
      minWidth: '100px',
    },
  ]

  const hasAdminNotes = Boolean(selectedBillingCycle?.notesForAdmin)
  const showBillingNotes = Boolean(selectedBillingCycle?.notesForBilling) && isWpcExecutive
  const showNotes = hasAdminNotes || showBillingNotes

  return (
    <div className="pt-8">
      <div className="flex flex-wrap items-center justify-between gap-4">
        <div className="flex flex-wrap items-center gap-4">
          {selectedBillingCycle?.id && (
            <Select
              className="relative w-full cursor-default rounded-md border border-none bg-transparent px-3 py-1 pl-0 text-left text-gray-900 shadow-none"
              inputTextClassName="text-lg"
              options={billingCycles}
              value={selectedBillingCycle}
              onChange={setSelectedBillingCycle}
              disabled={billingCycles.length === 0}
              showBadge
              dataTestId="billingCycleSelect"
            />
          )}
        </div>

        {canPerformActions && (
          <Button
            icon={<AddIcon className="h-4 stroke-white" />}
            label="Add Credit Memo"
            size="sm"
            dataTestId="addCreditMemo"
            disabled={!selectedBillingCycle?.id}
            onClick={() => {
              setShowCreditModal(true)
              setSelectedCreditMemo({})
            }}
          />
        )}
      </div>

      {showNotes && (
        <Disclosure defaultOpen>
          {({ open }) => (
            <div
              className="my-4 bg-[#ffffff] px-6 py-2"
              style={{
                border: `1px solid #cfd0d2`,
                borderRadius: '16px',
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
              }}
            >
              <DisclosureButton className="w-full">
                <div className="flex flex-row items-center justify-between gap-4">
                  <span className="text-charcoal-900 text-md flex-none font-medium">
                    Billing and Approval Notes
                  </span>
                  <button type="button">
                    {open ? (
                      <MinusIcon className="h-4 w-4 stroke-gray-700" />
                    ) : (
                      <AddIcon className="h-4 w-4 stroke-gray-700" />
                    )}
                  </button>
                </div>
              </DisclosureButton>
              <DisclosurePanel className="text-charcoal-900 mt-4 flex flex-col gap-1 p-0 text-sm font-medium">
                <p>{selectedBillingCycle.notesForAdmin}</p>
                {showBillingNotes && <p>{selectedBillingCycle.notesForBilling}</p>}
              </DisclosurePanel>
            </div>
          )}
        </Disclosure>
      )}

      <div className="mt-4">
        <DataTable
          columns={columns}
          data={memos}
          onSort={(column, direction) => {
            const d = direction === 'asc' ? '' : '-'
            setSortedColumn(`${d}${column.sortBy}`)
          }}
          progressPending={loadingCreditMemos || loadingBillingCycle}
          sortServer
          expandableRows
          expandableIcon={{
            collapsed: (
              <div className="flex flex-row gap-3 pl-3">
                <AddIcon className="h-4 stroke-blue-800" />
              </div>
            ),
            expanded: (
              <div className="flex flex-row gap-3 pl-4">
                <MinusIcon className="h-4 stroke-blue-800" />
              </div>
            ),
          }}
          expandableRowsComponent={ExpandedCreditMemoRow}
          pagination
          paginationServer
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 30, 50]}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={(currentRowsPerPage) => setPerPage(currentRowsPerPage)}
          onChangePage={(page) => {
            handlePagination(
              page,
              currentPage,
              perPage,
              totalRows,
              pages,
              setCurrentPage,
              getUpdatedMemos,
              `${MEMOS_BASE_URL}&limit=`,
              `order_by=${sortedColumn}`,
            )
          }}
        />
      </div>

      <div className="m-auto flex">
        {renderActionButtons(selectedBillingCycle.state, selectedBillingCycle.month)}
      </div>

      {showDeleteDialog && selectedCreditMemo && (
        <Dialog
          title="Delete Credit Memo"
          type="warning"
          message={`Are you sure you want to delete this credit memo (ID: ${selectedCreditMemo.memoId})?`}
          onConfirm={() =>
            deleteCreditMemo(
              dealerId,
              selectedBillingCycle.id,
              selectedCreditMemo.id,
              setLoadingDelete,
              handleErrors,
              () => {
                setShowDeleteDialog(false)
                setSelectedCreditMemo({})

                getUpdatedMemos(
                  `${MEMOS_BASE_URL}&limit=${perPage}&page=${currentPage}&order_by=${sortedColumn}`,
                )
              },
            )
          }
          onCancel={() => setShowDeleteDialog(false)}
          loading={loadingDelete}
        />
      )}

      {showCreditModal && (
        <CreditMemoModal
          closeModal={() => {
            setShowCreditModal(false)
            setSelectedCreditMemo({})
            fetchCreditMemo(selectedBillingCycle.id)
          }}
          dealerId={dealerId}
          billingCycles={outstandingBillingCycles}
          loadingBillingCycles={loadingOutstandingBillingCycles}
          creditMemo={selectedCreditMemo}
          readOnly={showReadOnlyModal}
        />
      )}

      <RejectionModal
        showModal={showRejectionModal}
        setShowModal={setShowRejectionModal}
        billingCycleId={selectedBillingCycle?.id}
        dealerId={dealerId}
        getUpdatedBillingCycle={() => {
          fetchBillingCycles()
        }}
      />

      <SubmitForApprovalModal
        showModal={showSubmitForApprovalModal}
        setShowModal={setShowSubmitForApprovalModal}
        selectedBillingCycle={selectedBillingCycle}
        billingCycleId={selectedBillingCycle?.id}
        dealerId={dealerId}
        getUpdatedBillingCycle={() => {
          fetchBillingCycles()
        }}
      />

      <ApprovalModal
        showModal={showApprovalModal}
        setShowModal={setShowApprovalModal}
        selectedBillingCycle={selectedBillingCycle}
        billingCycleId={selectedBillingCycle?.id}
        dealerId={dealerId}
        getUpdatedBillingCycle={() => {
          fetchBillingCycles()
        }}
      />

      {showReopenModal && (
        <Dialog
          title="Reopen Billing Month"
          type="warning"
          message="Are you sure you want to reopen this billing month? An email will be sent to notify the Billing Department of this action."
          onConfirm={() =>
            updateBillingCycle(
              dealerId,
              selectedBillingCycle?.id,
              {
                markAsReopened: true,
              },
              setLoadingReopen,
              handleErrors,
              () => {
                handleSuccess('Billing cycle is reopened.')
                setShowReopenModal(false)
                fetchBillingCycles()
              },
            )
          }
          onCancel={() => setShowReopenModal(false)}
          loading={loadingReopen}
        />
      )}
    </div>
  )
}
CreditMemos.propTypes = {
  dealerId: PropTypes.string,
}

export default CreditMemos
