import { useCallback, useEffect, useRef, useState } from 'react'

// Utils
import { convertIncomingData } from '../services/transforms'

function useWebSocket({ path, onMessage, token, enabled = true, config = {} }) {
  const [isConnected, setIsConnected] = useState(false)
  const [reconnectAttempts, setReconnectAttempts] = useState(0)
  const [isMaxedOut, setIsMaxedOut] = useState(false)
  const reconnectTimeoutRef = useRef(null)
  const isConnectingRef = useRef(false)
  const wsRef = useRef(null)
  const unmountedRef = useRef(false)

  const log = useCallback((message, level = 'info') => {
    if (config.debug) {
      console[level](`[WebSocket] ${message}`)
    }
  }, [])

  const cleanupConnection = useCallback(() => {
    isConnectingRef.current = false
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current)
      reconnectTimeoutRef.current = null
    }
    if (wsRef.current) {
      wsRef.current.close()
      wsRef.current = null
    }
    setIsConnected(false)
  }, [])

  const connect = useCallback(() => {
    if (unmountedRef.current || !enabled || isConnectingRef.current) {
      log('Connection skipped - unmounted, disabled, or already connecting')
      return null
    }

    const maxRetries = config.maxRetries ?? 5
    if (reconnectAttempts >= maxRetries) {
      log('Connection rejected - max retries reached')
      setIsMaxedOut(true)
      return null
    }

    if (!path) {
      log('Connection rejected - invalid path')
      return null
    }

    cleanupConnection()
    isConnectingRef.current = true

    const wsUrl = `${import.meta.env.VITE_WS_BASE_URL}${path}/?${token ? `token=${token}` : ''}`
    log(`Connecting to ${wsUrl}`)
    const ws = new WebSocket(wsUrl)
    wsRef.current = ws

    ws.onopen = () => {
      log('Connected')
      isConnectingRef.current = false
      setIsConnected(true)
      setReconnectAttempts(0)
    }

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data)
        onMessage(convertIncomingData(data))
      } catch (error) {
        log(`Error parsing message: ${error}`, 'error')
      }
    }

    ws.onclose = (event) => {
      log(`Connection closed with code ${event.code}`, 'warn')
      isConnectingRef.current = false
      setIsConnected(false)
      wsRef.current = null

      if (!unmountedRef.current && reconnectAttempts < maxRetries && enabled) {
        setReconnectAttempts((prev) => {
          const nextAttempt = prev + 1
          if (nextAttempt >= maxRetries) {
            log('Max retries reached, giving up', 'warn')
            setIsMaxedOut(true)
            return prev
          }

          const delay = Math.min(1000 * 2 ** prev, 10000)
          log(`Scheduling reconnect attempt ${nextAttempt} in ${delay}ms`)

          reconnectTimeoutRef.current = setTimeout(() => {
            connect()
          }, delay)

          return nextAttempt
        })
      }
    }

    ws.onerror = (error) => {
      log(`WebSocket error: ${error}`, 'error')
      isConnectingRef.current = false
    }

    return ws
  }, [log, token, cleanupConnection])

  useEffect(() => {
    unmountedRef.current = false

    if (enabled) {
      connect()
    } else {
      cleanupConnection()
    }

    return () => {
      unmountedRef.current = true
      cleanupConnection()
    }
  }, [enabled, connect, cleanupConnection])

  return {
    isConnected,
    reconnectAttempts,
    isMaxedOut,
    disconnect: cleanupConnection,
    reconnect: () => {
      setIsMaxedOut(false)
      setReconnectAttempts(0)
      connect()
    },
    enabled,
  }
}
export { useWebSocket }
