import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

// Components
import { LegalLinks } from '../../components/LegalLinks'
import { Button } from '../../components/Button'
import { FormHeader } from '../../components/FormHeader'
import { TextInput } from '../../components/TextInput'

// Service
import { forgotPassword } from '../../services/user.service'

// Utils
import { toast } from '../../utils/helpers'

/**
 *
 * PasswordResetRequest
 *
 */
const PasswordResetRequest = () => {
  // State
  const [loading, setLoading] = useState(false)

  const handleSuccesses = (message) => toast(message, 'success')
  const handleErrors = (message) => toast(message, 'error')

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
    },
  })

  /**
   * Handles form submission
   * - Clears form if successful
   * @param {object} data
   */
  const onSubmit = async (data) =>
    forgotPassword(data, handleErrors, setLoading, (m) => {
      handleSuccesses(m)

      // Reset form
      reset()
    })

  return (
    <div className="flex h-full w-full justify-center px-4 sm:px-6 lg:px-8">
      <div className="z-10 mx-4 w-full max-w-md space-y-6 rounded-xl bg-[#FFFFFF] px-6">
        <FormHeader title="Forgot Password">
          <div className="flex w-full justify-center">
            <a
              href="/login"
              className="text-sm font-semibold uppercase leading-[14px] tracking-[1.5px] text-blue-800 hover:text-blue-900"
            >
              Log In
            </a>
          </div>
        </FormHeader>

        <form
          className="flex flex-col gap-4"
          id="password-reset-request-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            className="bg-brownGray-100"
            data-testid="email"
            error={errors.email && 'This field is required'}
            autoComplete="email"
            id="email"
            label="Email"
            name="email"
            placeholder="Email"
            required
            {...register('email', { required: true })}
          />

          {/* Submit */}
          <Button fullWidth label="Submit" loading={loading} />
        </form>

        <LegalLinks />

        <div className="h-[1px]" />
      </div>
    </div>
  )
}

export default PasswordResetRequest
