import React from 'react'

/**
 * PrivacyPolicy
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */
function PrivacyPolicy() {
  return (
    <div className="bg-background flex h-full w-full flex-col pb-12">
      <main className="mx-auto flex w-full max-w-7xl grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="mb-4 flex w-full flex-col items-center gap-4">
          <span className="text-3xl font-bold">Privacy Policy</span>
          <a
            href="/login"
            className="text-sm font-semibold uppercase leading-[14px] tracking-[1.5px] text-blue-800 hover:text-blue-900"
          >
            Log In
          </a>
        </div>

        <span className="text-lg">
          This privacy notice discloses the privacy practices for the WARP platform. This privacy
          notice applies solely to information collected by this website. It will notify you of the
          following:
        </span>

        <ol className="mt-2 text-lg">
          <li className="list-inside list-decimal">
            What personally identifiable information is collected from you through the website, how
            it is used and with whom it may be shared.
          </li>
          <li className="list-inside list-decimal">
            What choices are available to you regarding the use of your data.
          </li>
          <li className="list-inside list-decimal">
            The security procedures in place to protect the misuse of your information.
          </li>
          <li className="list-inside list-decimal">
            How you can correct any inaccuracies in the information.
          </li>
        </ol>

        <span className="mt-4 text-xl font-bold">Information Collection, Use, and Sharing</span>

        <ol className="text-lg">
          <li className="list-inside list-disc">
            We are the sole owners of the information collected on this site.
          </li>
          <li>
            We only have access to/collect information that you voluntarily give us via email or
            other direct contact from you.
          </li>
          <li className="list-inside list-disc">
            We will not sell or rent this information to anyone.
          </li>
          <li className="list-inside list-disc">
            We will use your information to respond to you, regarding the reason you contacted us.
          </li>
          <li className="list-inside list-disc">
            Unless you ask us not to, we may contact you via email in the future to tell you about
            specials, new products or services, or changes to this privacy policy.
          </li>
        </ol>

        <span className="mt-4 text-xl font-bold">Your Access to and Control Over Information</span>

        <span className="text-lg">
          You may opt out of any future contacts from us at any time. You can do the following at
          any time by contacting us via the email address or phone number given on our website:
        </span>

        <ol className="text-lg">
          <li className="list-inside list-disc">See what data we have about you, if any.</li>
          <li className="list-inside list-disc">Change/correct any data we have about you</li>
          <li className="list-inside list-disc">Have us delete any data we have about you.</li>
          <li className="list-inside list-disc">
            Express any concern you have about our use of your data.
          </li>
        </ol>

        <span className="mt-4 text-xl font-bold">Security</span>

        <span className="text-lg">
          We take precautions to protect your information. When you submit contact information,
          your information is protected both online and offline. Wherever we collect information
          (such as name and email), that information is encrypted and transmitted in a secure way.
          You can verify this by looking for a lock icon in the address bar and looking for
          &quot;https&quot; at the beginning of the address of the Web page. While we use
          encryption to protect sensitive information transmitted online, we also protect your
          information offline. The computers/servers in which we store personally identifiable
          information are kept in a secure environment.
        </span>

        <span className="mt-4 text-lg font-bold">
          If you feel that we are not abiding by this privacy policy, you should contact us
          immediately.
        </span>
      </main>
    </div>
  )
}

export default PrivacyPolicy
