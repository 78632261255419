import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useMemo, useState } from 'react'

// Components
import { StateContainer } from '../../components/StateContainer'
import { Toggle } from '../../components/Toggle'

// Stores
import { UserStoreContext } from '../../stores/UserStore'

// Utils & Service
import { updateUser } from '../../services/user.service'
import { toast } from '../../utils/helpers'

const IN_APP_NOTIFICATION_OPTIONS = [
  {
    label: 'RO Status Update',
    value: 'inAppReadyForReview',
  },
  {
    label: 'New RO Comment',
    value: 'inAppNewComment',
  },
  {
    label: 'New RO Attachment',
    value: 'inAppNewAttachment',
  },
]

const EMAIL_NOTIFICATIONS_OPTIONS = [
  {
    label: 'RO Status Update',
    value: 'emailReadyForReview',
  },
]

const WPC_EMAIL_NOTIFICATIONS_OPTIONS = [...EMAIL_NOTIFICATIONS_OPTIONS]

if (import.meta.env.VITE_BILLING_MODULE_ENABLED === 'true') {
  WPC_EMAIL_NOTIFICATIONS_OPTIONS.push({ label: 'Billing Details', value: 'emailBilling' })
}

const WPC_IN_APP_NOTIFICATION_OPTIONS = [...IN_APP_NOTIFICATION_OPTIONS]

if (import.meta.env.VITE_BILLING_MODULE_ENABLED === 'true') {
  WPC_IN_APP_NOTIFICATION_OPTIONS.push({ label: 'Billing Details', value: 'inAppBilling' })
}

const EMAIL_OPTIONS = [
  {
    label: 'Daily Summary',
    value: 'emailDailyReport',
  },
  {
    label: 'Monthly Summary',
    value: 'emailMonthlyReport',
  },
]

/**
 *
 * Settings
 *
 */
const Settings = observer(() => {
  // Context
  const {
    error: loadingError,
    isDealerUser,
    isWpcUser,
    loading,
    getUpdatedUser,
    setCurrentUser,
    user,
  } = useContext(UserStoreContext)

  // State
  const [loadingSettingChange, setLoadingSettingChange] = useState(null)

  const handleSuccesses = (message) => toast(message, 'success')
  const handleErrors = (message) => toast(message, 'error')

  useEffect(() => {
    getUpdatedUser(user.id)
  }, [])

  const renderNotificationToggles = (options) =>
    _.map(options, (n) => (
      <div className="bg-brownGray-100 flex w-full rounded-lg p-6" key={n.value}>
        <Toggle
          disabled={loading || loadingSettingChange === n.value}
          label={n.label}
          name={n.value}
          onChange={async (checked) => {
            setLoadingSettingChange(n.value)
            await updateUser(
              {
                id: user.id,
                notificationSettings: { [n.value]: checked },
              },
              handleErrors,
              () => {},
              () => handleSuccesses('Notification settings updated successfully.'),
              setCurrentUser,
            )
            setLoadingSettingChange(null)
          }}
          checked={user.notificationSettings && user.notificationSettings[n.value]}
        />
      </div>
    ))

  const emailNotifications = useMemo(() => {
    // Dealer Users can opt in for all email notifications
    if (isDealerUser) {
      return [...EMAIL_NOTIFICATIONS_OPTIONS, ...EMAIL_OPTIONS]
    }
    // WPC Managers Can opt in for a daily summary email
    if (user.wpcUserProfile?.role === 'Manager') {
      return [EMAIL_OPTIONS[0], ...WPC_EMAIL_NOTIFICATIONS_OPTIONS]
    }

    // Otherwise, only the RO Status Update and billing details email notification are available
    return WPC_EMAIL_NOTIFICATIONS_OPTIONS
  }, [isDealerUser, isWpcUser])

  const inAppNotifications = useMemo(() => {
    // WPC Users can configure notifications for billing
    if (isWpcUser) {
      return WPC_IN_APP_NOTIFICATION_OPTIONS
    }
    // Otherwise, only the RO Status Update email notification is available
    return IN_APP_NOTIFICATION_OPTIONS
  }, [isDealerUser, isWpcUser])

  return (
    <div className="bg-background flex h-full w-full overflow-y-auto px-4 pb-12 pt-6 sm:px-6 lg:px-8">
      <StateContainer error={loadingError} loading={loading}>
        <div className="flex h-full w-full flex-col">
          <span className="text-midnight mb-5 self-start text-start text-2xl font-semibold leading-8 tracking-[-1px]">
            Settings
          </span>

          <div className="mx-8 flex flex-col">
            <span className="text-charcoal-900 mb-5 self-start text-start text-lg font-semibold leading-8 tracking-[-1px]">
              In-App Notification Settings
            </span>

            <div className="space-y-6">{renderNotificationToggles(inAppNotifications)}</div>
          </div>

          <div className="mx-8 mt-8 flex flex-col pb-12">
            <span className="text-charcoal-900 mb-5 self-start text-start text-lg font-semibold leading-8 tracking-[-1px]">
              Email Notification Settings
            </span>

            <div className="space-y-6">{renderNotificationToggles(emailNotifications)}</div>
          </div>
        </div>
      </StateContainer>
    </div>
  )
})

export default Settings
